import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbTypeahead, NgbHighlight } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../../../core/services/utility.service';
import { ThirtySecondsVideoRecordModalComponent } from '../thirty-seconds-video-record-modal/thirty-seconds-video-record-modal.component';
import { CitySuggestionComponent } from '../city-suggestion/city-suggestion.component';
import { InputRestrictionDirective } from '../../directives/input-restriction.directive';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

@Component({
    selector: 'add-experience-modal',
    templateUrl: './add-experience-modal.component.html',
    styleUrls: ['./add-experience-modal.component.scss'],
    standalone: true,
    imports: [NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, FormsModule, NgbTypeahead, InputRestrictionDirective, NgbHighlight, CitySuggestionComponent]
})
export class AddExperienceModalComponent implements OnInit, AfterViewInit {

	@Input() data: any = {};
	@Input() id: any;
	@Input() delete: boolean = false;
	@Input() pageStatus: any;
	@Input() ctrl: any;
	@Output() deleteFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() getEditdataFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() experienceAddedFn: EventEmitter<any> = new EventEmitter<any>();
	@Output() getEditdataFnGs: EventEmitter<any> = new EventEmitter<any>();
	@Output() experienceAddedFnGs: EventEmitter<any> = new EventEmitter<any>();


	public fileServerUrl: string = environment.urls.fileServerUrl_Obj;

	private companySelected: boolean = false;
	private citySelected: boolean = false;
	private videoData: any;
	private oldData: any;
	private oldDataGT: any;
	private inGetStartedController: any;

	public videoEditted: boolean = false;
	public videoDeleted: boolean = false;
	public muteClassAdd: boolean = true;
	public posSelected: boolean = false;
	public startYear: string = 'Year:';
	public currentYear: any = new Date().getFullYear();
	public maximumYear: any = this.currentYear + 12;
	public endYear: string = 'Year:';
	public company: string = '';
	public companyId: any;
	public companyErrorMessage: string = '';
	public showCompanyError: boolean = false;
	public companyUrl: any;
	public position: string = '';
	public positionId: any;
	public positionErrorMessage: string = '';
	public showPositionError: boolean = false;
	public city: string = '';
	public cityErrorMessage: string = '';
	public showCityError: boolean = false;
	public description: string = '';
	public endMonth: any = { id: '0' };
	public endDateErrorMessage: string = '';
	public endDateError: boolean = false;
	public startMonth: any = { id: '0' };
	public startDateErrorMessage: string = '';
	public startDateError: boolean = false;
	public timePeriodErrorMessage: string = '';
	public timePeriodError: boolean = false;
	public selectedVideoUrl: any;
	public videoSelected: any;
	public rawVideoUrl: any;
	public isCurrentlyWorking: any;
	public maxLength: number = 2000;
	public suggestionsCompany: any[] = [];
	public hasToShowCompanySuggestions: boolean = false;
	public showCompanyAddtolist: boolean = false;
	public suggestionsPosition: any[] = [];
	public hasToShowPositionSuggestions: boolean = false;
	public showpositionAddtolist: boolean = false;
	public photoSelected: boolean = false;
	public selectedPhoto: any;

	public parentobj: any = {
		city: '',
		showCityError: ''
	};

	public valueUpdated: boolean = false;

	constructor(public modal: NgbActiveModal,
		private sanitizer: DomSanitizer,
		private autocompleteService: AutocompleteService,
		private eventService: EventService,
		private modalService: NgbModal,
		private utilityService: UtilityService) { }

	ngOnInit() {
		this.data.months_new = [
			{ id: '0', name: 'Month' },
			{ id: '1', name: 'January' },
			{ id: '2', name: 'February' },
			{ id: '3', name: 'March' },
			{ id: '4', name: 'April' },
			{ id: '5', name: 'May' },
			{ id: '6', name: 'June' },
			{ id: '7', name: 'July' },
			{ id: '8', name: 'August' },
			{ id: '9', name: 'September' },
			{ id: '10', name: 'October' },
			{ id: '11', name: 'November' },
			{ id: '12', name: 'December' }
		];

		this.data.months_start = [...this.data.months_new];
		this.data.months_end = [...this.data.months_new];
		// condition if edit on get started page
		if (this.id && this.data) {
			this.inGetStartedController = true;
			let data = this.data;
			this.oldDataGT = this.data;
			if (data) {
				this.id = data.id;
				this.company = data.companyName;
				this.companyId = data.companyName;
				this.companyUrl = data.companyUrl;
				this.position = data.positionName;
				this.positionId = data.positionName;
				this.city = data.city;
				this.description = data.description;
				this.startYear = data.startYear.toString();
				this.endYear = data.endYear.toString();
				this.startMonth = { id: data.startMonth };
				this.endMonth = { id: data.endMonth };
				this.videoData = data.videoUrl;
				this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.videoUrl);
				this.videoSelected = !((data.videoUrl.toString() === '' || data.videoUrl.toString() === this.fileServerUrl));
				this.rawVideoUrl = data.videoUrl;
				this.isCurrentlyWorking = data.isWorking;
			}
		}

		// condition if on profile page
		if (this.data && this.data.COGUID) {
			this.inGetStartedController = false;
			let data = this.data;
			this.oldData = this.data;
			this.id = data.Id;
			if (data) {
				this.id = data.Id;
				this.company = data.Employer.Name;
				this.companyId = data.Employer.Name;
				this.companyUrl = data.Employer.LogoUrl;
				this.position = data.JobTitle;
				this.positionId = data.JobTitle;
				this.city = data.Location;
				this.description = data.Notes || '';
				this.startYear = data.StartDate.toString();
				this.endYear = data.EndDate.toString();
				this.startMonth = (data.StartMonth === undefined) ? 'Month' : { id: data.StartMonth };
				this.endMonth = (data.EndMonth === undefined) ? 'Month' : { id: data.EndMonth };
				this.videoData = undefined;
				this.videoSelected = !((data.VideoUrl.toString() === '' || data.VideoUrl.toString() === this.fileServerUrl));
				this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.VideoUrl.toString());
				this.rawVideoUrl = '';
				this.isCurrentlyWorking = (data.EndDate === 0 || data.EndDate === 'Present');
			}
		}

		this.eventService.subscribe('video-selected', (value) => {
			// video selected
			this.muteClassAdd = true;
			this.videoEditted = true;
			this.videoSelected = true;
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value.data.url);
			this.videoData = value.data.videoData;
			this.rawVideoUrl = value.data.url;
			if (document.getElementById('muteBtn')) {
				this.muted(true);
			}
			this.valueUpdated = true;
		});

	}

	ngAfterViewInit() {
		this.muted(true);
	}

	remaining() {
		return this.maxLength - document.getElementsByTagName('textarea')[0].value.length;
	}

	muted(val: boolean) {
		if (val !== undefined && val !== null) {
			(document.getElementById('vidPreview') as HTMLMediaElement).muted = val;
			this.muteClassAdd = val;
		}
	}

	recordVideo() {
		this.muteClassAdd = true;
		this.muted(true);
		let modal = this.modalService.open(ThirtySecondsVideoRecordModalComponent,
			{ windowClass: 'modal-theme-unnanu-v2 video-recorder-popup', backdrop: 'static', keyboard: false });
	}

	positionVideo() {
		let selectedVideo = (document.querySelector('#vidPreview') as HTMLElement);
		let leftMargin = ((selectedVideo.offsetWidth - selectedVideo.offsetHeight) / 2) * -1;
		(document.getElementById('vidPreview') as HTMLElement).style.marginLeft = `${leftMargin}px`;
		if (!this.videoEditted) {
			this.muted(true);
		}
	}


	suggestCompanies() {
		this.validateCompany();
		this.companySelected = false;
		if (this.company) {
			if (this.company.length >= 3) {
				return this.autocompleteService.get(this.company, 'company').pipe(map((response: any) => {
					if (
						response.status === 200 &&
						response.Code === 200 &&
						response.Data.length > 0
					) {
						this.suggestionsCompany = response.Data;
						this.hasToShowCompanySuggestions = true;
						this.showCompanyAddtolist = false;
						return this.suggestionsCompany;
					} else {
						// Add New Position
						this.suggestionsCompany = [];
						this.hasToShowCompanySuggestions = false;
						return this.suggestionsCompany;
					}
				})
				);
			} else {
				this.hasToShowCompanySuggestions = false;
				return of([]);
			}
		} else {
			return of([]);
		}
	}

	searchCompany: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestCompanies())
		)

	companySelectedFn(event: any) {
		event.preventDefault();
		let companyName = event.item.Name;
		let id = event.item.AuthorityId;
		let url = event.item.LogoUrl;
		if (companyName !== '') {
			this.company = companyName;
			this.companyId = id;
			this.companyUrl = url;
			this.hasToShowCompanySuggestions = false;
			this.companySelected = true;
			this.showCompanyAddtolist = false;
		} else {
			this.company = '';
			this.showCompanyAddtolist = false;
		}
	}

	hideCompanySuggestions() {
		this.validateCompany()
		this.hasToShowCompanySuggestions = false;
		if (!this.companySelected) {
			if (!this.inGetStartedController && this.oldData) {
				if (this.oldData.Authority.Name !== this.company) {
					// this.company = '';
				}
			} else {
				// this.company = '';
				if (this.inGetStartedController && this.oldDataGT) {
					if (this.oldDataGT.authority !== this.company) {
						// this.company = '';
					}
				} else {
					// this.company = '';
				}
			}
			this.validateCompany();
		}
	}

	validateCompany() {
		if (this.company === '') {
			this.companyErrorMessage = 'Enter certifying authority';
			this.showCompanyError = true;
		} else {
			this.showCompanyError = false;
		}
	}

	suggestPositions() {
		this.validatePosition();
		if (this.position.length >= 3) {
			return this.autocompleteService.get(this.position, 'profession').pipe(map((response: any) => {
				if (
					response.status === 200 &&
					response.Code === 200 &&
					response.Data.length > 0
				) {
					this.suggestionsPosition = response.Data;
					this.hasToShowPositionSuggestions = true;
					return this.suggestionsPosition;
				} else {
					// Add New Position
					this.suggestionsPosition = [];
					this.hasToShowPositionSuggestions = false;
					return this.suggestionsPosition;
				}
			})
			);
		} else {
			this.hasToShowPositionSuggestions = false
			return of([]);
		}
	}

	searchPosition: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestPositions())
		)

	positionFormatter = (result: any) => result.Name;

	positionSelected(event: any) {
		event.preventDefault();
		let positionName = event.item.Name;
		let id = event.item.Id;
		if (positionName !== '') {
			this.posSelected = true;
			this.position = positionName;
			this.positionId = id;
			this.showpositionAddtolist = false;
		} else {
			this.position = '';
		}
		this.showpositionAddtolist = false;
	}

	hidePositionSuggestions() {
		this.hasToShowPositionSuggestions = false
		if (!this.posSelected) {
			if (!this.inGetStartedController && this.oldData) {
				if (this.oldData.JobTitle !== this.position) {
					// this.position = '';
				}
			} else {
				// this.position = '';
				if (this.inGetStartedController && this.oldDataGT) {
					if (this.oldDataGT.positionName !== this.position) {
						// this.position = '';
					}
				} else {
					// this.position = '';
				}
			}
			this.validatePosition();
		}
	}

	validatePosition() {
		if (this.position === '') {
			this.positionErrorMessage = 'Please select your position';
			this.showPositionError = true;
		} else {
			this.showPositionError = false;
		}
	}

	validateCity() {
		if (this.city === '' && this.parentobj.city === '') {
			this.cityErrorMessage = 'Enter location';
			this.showCityError = true;
		} else {
			this.showCityError = false;
		}
	}

	deleteData() {
		if (this.delete) {
			this.deleteFn.emit(this.data.COGUID);
			this.close();
		}
	}

	saveData() {
		this.validateTimePeriod()
		this.validatePosition();
		this.validateCity();
		this.validateCompany();

		if ((this.showCompanyError || this.showPositionError || this.parentobj.showCityError || this.startDateError || this.endDateError || this.timePeriodError)) {

		} else {
			let guid;
			if (this.data.id) {
				guid = this.id;
			} else if (this.data && this.data.COGUID) {
				guid = this.data.COGUID;
			} else {
				guid = this.utilityService.genGuid();
			}
			let experienceObject = {
				key: guid,
				value: {
					id: this.id,
					companyName: this.company,
					companyId: this.company,
					companyUrl: this.companyUrl,
					positionName: this.position,
					positionId: this.position,
					city: (this.parentobj.city !== '' ? this.parentobj.city : this.city),
					description: this.description,
					startYear: this.startYear,
					endYear: (this.isCurrentlyWorking) ? 0 : this.endYear,
					startMonth: Number(this.startMonth.id),
					endMonth: Number(this.endMonth.id),
					videoData: this.videoData,
					videoUrl: (this.selectedVideoUrl) ? this.selectedVideoUrl.toString() : '',
					isWorking: this.isCurrentlyWorking,
					isSaved: false
				}
			}
			console.log(experienceObject)
			if (this.id || (this.data && this.data.COGUID)) {
				if (this.pageStatus === 1) {
					this.getEditdataFn.emit({ data: experienceObject, videoEditted: this.videoEditted, ctrl: this.ctrl, videoDeleted: this.videoDeleted })
				} else {
					this.getEditdataFnGs.emit({ data: experienceObject, videoEditted: this.videoEditted, ctrl: this.ctrl, videoDeleted: this.videoDeleted })
				}
			} else {
				if (this.pageStatus === 1) {
					this.experienceAddedFn.emit({ data: experienceObject, ctrl: this.ctrl, videoEditted: this.videoEditted })
				} else {
					this.experienceAddedFnGs.emit({ data: experienceObject, ctrl: this.ctrl, videoEditted: this.videoEditted })
				}
			}
			this.close();
		}
	}

	currentlyWorking() {
		this.endYear = 'Year:';
		this.endMonth = { id: '0' };
	}

	// time period field validation
	private validateTimePeriod() {
		if (this.startMonth.id === '0' || this.startMonth.name === 'Month' || this.startYear === 'Year:') {
			this.startDateErrorMessage = 'Please enter the joining date';
			this.startDateError = true;
		} else {
			this.startDateError = false;
		}

		if (this.endMonth.id === '0' || this.endMonth.name === 'Month' || this.endYear === 'Year:') {
			this.endDateErrorMessage = 'Select month & year';
			this.endDateError = true;
		} else {
			this.endDateError = false;
		}

		if (Number(this.startYear) > Number(this.endYear) && Number(this.endYear) !== 0) {
			this.timePeriodErrorMessage = 'End year cannot be before start year';
			this.timePeriodError = true;
		} else {
			// this.timePeriodError = false
			if (Number(this.startYear) === Number(this.endYear) && Number(this.startMonth.id) > Number(this.endMonth.id)) {
				this.timePeriodErrorMessage = 'End year cannot be before start year';
				this.timePeriodError = true;
			} else {
				this.timePeriodError = false;
			}
		}

		if (this.isCurrentlyWorking) {
			this.endDateError = false;
		}
	}

	removeVideo() {
		this.videoEditted = false;
		this.videoDeleted = true;
		this.videoSelected = false;
		this.selectedVideoUrl = undefined;
		this.videoData = undefined;
		if (document.getElementById('vidPreview')) {
			(document.getElementById('vidPreview') as HTMLMediaElement).muted = true;
		}
	}

	getArray(n: number) {
		return new Array(n);
	}

	adjustCalenderStart(selectedDate: any) {
		console.log(selectedDate)
		let date = new Date()
		if (date.getFullYear() === Number(selectedDate)) {
			this.data.months_start = this.data.months_start.splice(0, date.getMonth() + 2);
		} else {
			if (this.data.months_start.length !== 13) {
				this.data.months_start = [...this.data.months_new];
			}
		}
	}

	adjustCalenderEnd(selectedDate: any) {
		console.log(selectedDate)
		let date = new Date()
		if (date.getFullYear() === Number(selectedDate)) {
			this.data.months_end = this.data.months_end.splice(0, date.getMonth() + 2);
		} else {
			if (this.data.months_end.length !== 13) {
				this.data.months_end = [...this.data.months_new];
			}
		}
	}

	onChange(event: any){
		this.valueUpdated = true;
	}

	close() {
		this.modal.dismiss();
	}

}
