import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonAlertComponent } from '../common-alert/common-alert.component';
import { NgClass, DatePipe } from '@angular/common';
import { AttachmentViewDirective } from '../../directives/attachment-view.directive';

@Component({
    selector: 'onboarding-modal',
    templateUrl: './onboarding-modal.component.html',
    styleUrls: ['./onboarding-modal.component.scss'],
    standalone: true,
    imports: [AttachmentViewDirective, NgClass, DatePipe]
})
export class OnboardingModalComponent implements OnInit {

	@Input() offer: any;
	@Input() attachmentUrl: any;
	@Output() previewAttachmentView: EventEmitter<any> = new EventEmitter<any>();

	constructor(public modal: NgbActiveModal,
		private modalService: NgbModal) { }

	ngOnInit() {

	}

	onPreviewAttachmentView(event: any, id: any) {
		if (event.target.files.length > 0) {
			this.previewAttachmentView.emit({ file: event.target.files[0], id: id });
		}
	}

	openComment(comment: string){
		let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Comment';
		modal.componentInstance.msg = comment;
		modal.componentInstance.contentType = 'HTML';
	}

	closeThisDialog() {
		this.modal.dismiss();
	}

}
