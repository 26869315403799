@if (getFooterContext()) {
	<footer class="footer mt-auto" [ngClass]="{'wide': getFooterContext() === 'Wide'}">
	  <div [ngClass]="{'container': getFooterContext() === 'Default', 'container-fluid': getFooterContext() === 'Wide'}" style="padding-left: 1px;padding-right: 1px;">
		<div class="row align-items-center justify-content-between">
		  <!-- Copyright Section -->
		  <div class="col-auto ">
			© {{currentYear}} Unnanu Talent.
		  </div>

		  <!-- Footer Links -->
		  <div class="col-auto">
			<nav class="footer-links">
			  <a href="{{unnanu_landing_page}}/tos" target="_blank" class="text-decoration-none">Terms</a> ·
			  <a href="{{unnanu_landing_page}}/privacy" target="_blank" class="text-decoration-none">Privacy</a> ·
			  <a href="{{unnanu_landing_page}}/cookie" target="_blank" class="text-decoration-none">Cookie</a> ·
			  <a href="{{unnanu_landing_page}}/support" target="_blank" class="text-decoration-none">FAQ</a>

			</nav>
		  </div>
		</div>
	  </div>
	</footer>
  }
