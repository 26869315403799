import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from 'src/app/core/services/event.service';
import { SocketIOService } from 'src/app/core/services/socket-io.service';
import { UserService } from 'src/app/core/services/user.service';
import { CommonAlertComponent } from '../common-alert/common-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-linkedin-callback',
  standalone: true,
  imports: [],
  templateUrl: './linkedin-callback.component.html',
  styleUrl: './linkedin-callback.component.scss'
})
export class LinkedinCallbackComponent implements OnInit {

	constructor(
		private route:ActivatedRoute,
		private router:Router,
		private linkedinservice:UserService,
		private socketIOService: SocketIOService,
		private eventService: EventService,private modalService: NgbModal
	){}

ngOnInit(): void {
	this.eventService.subscribe('user-signed-in', (payload: any) => {
		console.log('payload: ', payload);
		this.userSignIn(payload);
	});
		// this.eventService.subscribe('user-signed-up', (payload: any) => {
		// 		console.log('payload: ', payload);
		// 		if (payload.response.Code === 200 || payload.response.Code === 2001) {
		// 			this.linkedinservice.setCookie('token', payload.response.Data.Token);
		// 			this.linkedinservice.setCookie('stage', '0');
		// 			this.router.navigateByUrl('/mobileVerify');
		// 		} else if (payload.response.Code === 1002) {
		// 			// HAS TO BE HANDLED WITH POPUP
		// 			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
		// 			modal.componentInstance.header = 'User with Email exists!';
		// 			modal.componentInstance.msg = 'An user account with this email address already exists. Please try again with a new email address.';
		// 		}
		// 	});
	const code = this.route.snapshot.queryParamMap.get('code');
	console.log("Authorization Code: ", code);
    const state = this.route.snapshot.queryParamMap.get('state');
    const savedState = sessionStorage.getItem('oauthState');

    if (!code || !state || state !== savedState) {
      console.error('Invalid state or code.');
      return;
    }
	  // Exchange authorization code for access token
	  this.linkedinservice.getAccessToken(code).subscribe(
		(tokenResponse: any) => {
		  const accessToken = tokenResponse;
		  console.log('accessToken: ', accessToken);
		  const data=tokenResponse.Data
		  console.log('accessToken: ', data);
		  const userData = {
			  linkedinId: data.sub,
			  firstName: data.given_name,
			  lastName: data.family_name,
			  email: data.email,
			  password:''
			};
			console.log('userData: ', userData);
			  this.nliAuthenticate(userData);
		  // Fetch LinkedIn user profile and email
		//   this.linkedinservice.getLinkedInProfile(accessToken).subscribe(
		// 	(profileResponse: any) => {
		// 	  this.linkedinservice.getLinkedInEmail(accessToken).subscribe(
		// 		(emailResponse: any) => {
		// 		  const userData = {
		// 			linkedinId: profileResponse.id,
		// 			firstName: profileResponse.localizedFirstName,
		// 			lastName: profileResponse.localizedLastName,
		// 			email: emailResponse.elements[0]['handle~'].emailAddress,
		// 			password:''
		// 		  };

		// 		  // Use the `nliAuthenticate` method here
		// 		  this.nliAuthenticate(userData);
		// 		}
		// 	  );
		// 	}
		//   );
		},
		(error:any) => {
		  console.error('Error getting access token', error);
		}
	  );
	// Used for social login is user exists

}
private userSignIn(args: any) {
	console.log('args: ', args);
	this.linkedinservice.setCookie('token', args.response.Data.Token);
	this.linkedinservice.setCookie('stage', args.response.Data.Stage);
	this.linkedinservice.setCookie('user_id', args.response.Data.User_id);
	this.linkedinservice.setCookie('stageToRedirect', args.response.Data.Stage_To_Redirect);
	// connect messaging service
	if (this.socketIOService.getConnectionStatus() === false ||
		this.socketIOService.getConnectionStatus() === undefined) {
		this.socketIOService.connect();
	}

	if (Number(args.response.Data.Stage) === 5) {
		this.linkedinservice.profileInfo().subscribe((response: any) => {
			if (response.status === 200 && response.Code === 200) {
				console.log(response);
				this.eventService.broadcast('ShowResumePopup', response);
			}
		});
	}

	this.linkedinservice.redirectUser(Number(args.response.Data.Stage), true);
}
private nliAuthenticate(userData: any): void {
    if (userData.email) {
      this.linkedinservice.linkedinAuth({
		email: userData.email,
		token: userData.linkedinId,
		password: '',
	}).subscribe(
        (response: any) => {
			console.log('response: ', response);
          if (response.status === 200 && response.Code === 401) {
            // User is logging in for the first time; call signup
			let user = {
				email: userData.email,
				firstName: userData.firstName,
				lastName: userData.lastName,
				password: '',
				token: userData.linkedinId || '',
			};
			this.linkedinservice.signUp(user, 'LINKEDIN').subscribe((signUpResponse: any) => {
				console.log('Signup response: ', signUpResponse);

				if (signUpResponse.status === 200) {
					// Immediately try signing in again
					this.linkedinservice.linkedinAuth({
						email: userData.email,
						token: userData.linkedinId,
						password: '',
					}).subscribe((authResponse: any) => {
						console.log('Auth response after signup: ', authResponse);
						if (authResponse.status === 200 && authResponse.Code === 200) {
							this.linkedinservice.setCookie('userEmail', userData.email);
							this.linkedinservice.setCookie('user_id', authResponse.Data.User_id);
							this.eventService.broadcast('user-signed-in', { response: authResponse });
						}
					});
				}
			});


          } else if (response.status === 200 && response.Code === 200) {
            // User already exists; set cookies and navigate
			console.log('userData.email: ', userData.email);
            this.linkedinservice.setCookie('userEmail', userData.email);
            this.linkedinservice.setCookie('user_id', response.Data.User_id);
			this.eventService.broadcast('user-signed-in', { response: response });
			// this.router.navigate(['/dashboard']);
          } else {
            console.error('Unexpected response during LinkedIn authentication', response);
          }
        },
        (error) => {
          console.error('Error during LinkedIn authentication', error);
        }
      );
    }
	else {
      console.error('LinkedIn authentication failed: Missing email');
    }

  }

}
