import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ContextService } from '../core/services/context.service';
import { SocketIOService } from '../core/services/socket-io.service';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DefaultHeaderComponent } from './default-header/default-header.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [DefaultHeaderComponent, DashboardHeaderComponent]
})
export class HeaderComponent implements OnInit {

	constructor(private contextService: ContextService,
		private socketIOService: SocketIOService,
		private cookieService: CookieService) { }

	ngOnInit(): void {
		if (this.cookieService.check('token') && (this.socketIOService.getConnectionStatus() === false ||
			this.socketIOService.getConnectionStatus() === undefined)) {
			this.socketIOService.connect();
		}
	}

	getHeaderContext() {
		return this.contextService.getHeaderContext();
	}

}
