import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { map, Observable } from 'rxjs';

@Injectable()
export class MediaUploadService {
	private serverUrl = environment.urls.serverUrl_Obj;

	constructor(private http: HttpClient,
		private utilityService: UtilityService) { }

	uploadVideo(videoData: any, type: any, id: any) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'undefined');
		// upload video
		let fd = new FormData();
		fd.append('uploadedFile', videoData);
		fd.append('FileType', type);
		fd.append('Id', id);
		fd.append('Web', 'WEB');

		return this.http.post(`${this.serverUrl}profile/video/create`, fd, { headers: headers });
	}

	uploadImage(imageData: any, type: any) {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'undefined');
		// upload photo
		let fd = new FormData();
		fd.append('uploadedFile', this.utilityService.base64toBlob(imageData));
		fd.append('FileType', type);

		return this.http.post(`${this.serverUrl}profile/image/create`, fd, { headers: headers });
	}

	removePhoto(type: any, id: any) {
		let data: any = {
			Type: type,
			RecordId: id.toString()
		};

		return this.http.post(`${this.serverUrl}user/video/delete`, data);
	}
	convertFiletopdf(fileUrl: any) {
		const requestBody = { file_url: fileUrl };
		return this.http.post(`https://unnanu-wmark-uat.azurewebsites.net/watermark`,requestBody,{ responseType: 'text',observe: 'response' },);
	}
processAttachmentUrl(attachURL: string): Observable<string> {
	return this.http.post('https://unnanu-wmark-uat.azurewebsites.net/watermark', { file_url: attachURL }, { observe: 'response' }).pipe(
		  map((response) => {
			if (response.status === 200) {
			  // Modify URL as per your requirements
	return attachURL.replace('resume', 'watermark').replace(/\.docx?|\.txt|\.rtf|\.odt|\.html|\.pdf$/i, '.pdf');
			} else {
	return attachURL.replace('resume', 'watermark').replace(/\.docx?|\.txt|\.rtf|\.odt|\.html|\.pdf$/i, '.pdf');
			}
		  })
		);
	  }
}
