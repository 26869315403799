<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{header}}</h4>
</div>
<div class="modal-body">
  @if (contentType === 'Text') {
    <div>{{msg}}</div>
  }
  @if (contentType === 'HTML') {
    <div [innerHTML]="msg"></div>
  }
</div>
<div class="modal-footer text-right">
  <div class="button-wrapper">
    @if (showCancelButton) {
      <button type="button" class="btn cancel-button" (click)="cancel()">Cancel</button>
    }
    <button type="button" class="btn ok-button" (click)="ok()">Ok</button>
  </div>
</div>
