import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { NgClass } from '@angular/common';
import { CameraComponent } from '../camera/camera.component';

@Component({
    selector: 'thirty-seconds-video-record-modal',
    templateUrl: './thirty-seconds-video-record-modal.component.html',
    styleUrls: ['./thirty-seconds-video-record-modal.component.scss'],
    standalone: true,
    imports: [CameraComponent, NgClass]
})
export class ThirtySecondsVideoRecordModalComponent implements OnInit {

	public recordTimeLimit: number = 30;
	public videoData: any;
	public timerText: string = ('0' + this.recordTimeLimit).slice(-2);
	public showRecordButton: boolean = true;
	public isMuted: boolean = true;
	public isRecording: boolean = false;

	private stream: any;
	private countdownTimer: any;

	constructor(private eventService: EventService,
		private modal: NgbActiveModal) {

	}

	ngOnInit() {
		this.eventService.subscribe('camera-started', (value) => {
			this.stream = value.localStream;
		});

		this.eventService.subscribe('camera-recording-complete', (value) => {
			this.videoData = value;
			this.stream = value.localStream;
		});
	}

	changeRecorderClass() {
		if (!this.isRecording) {
			// start recording
			this.startTimer();
			this.eventService.broadcast('start-recording-camera', {});
		} else {
			// stop recording
			clearInterval(this.countdownTimer);
			this.eventService.broadcast('stop-recording-camera', {});
			this.showRecordButton = false;
		}
		this.isRecording = !this.isRecording;
	}

	retakeVideo() {
		this.isRecording = false;
		this.showRecordButton = true;
		this.eventService.broadcast('camera-on', {});
	}

	selectVideo() {
		console.log('video selecteds');
		console.log('video duration = ' + (30 - Number(this.recordTimeLimit)));
		console.log(this.videoData);
		this.eventService.broadcast('video-selected', { data: this.videoData });
		this.close();
	}

	private startTimer() {
		let recordTimeLimit = 30;
		this.recordTimeLimit = recordTimeLimit;
		this.timerText = ('0' + this.recordTimeLimit).slice(-2);
		this.countdownTimer = setInterval(() => {
			this.recordTimeLimit = this.recordTimeLimit - 1;
			this.timerText = ('0' + this.recordTimeLimit).slice(-2);
			if (this.recordTimeLimit === 0) {
				clearInterval(this.countdownTimer);
				this.eventService.broadcast('stop-recording-camera', {});
				this.isRecording = !this.isRecording;
				this.showRecordButton = false;
			}
		}, 1000);
	}

	close() {
		this.eventService.broadcast('dismiss-camera', {});
		this.modal.dismiss();
	}

}
