<div class="edit-profile-dialog-content no-close-btn-header d-flex flex-row"
  [ngClass]="{'edit-resume-popup': ctrl === 'PROFILE_ADD_EDU'}">
  <div class="popup-column-group profile-photo-video-column">
    <div class="column-title">Education</div>
    <div class="photo-video-ui">
      <div class="photo-video-container">
        <div class="profile-video-ui-wrapper">
          <video (onloadeddata)="positionVideo()" id="vidPreview" [hidden]="!videoSelected"
          [src]="selectedVideoUrl" autoplay loop></video>
        </div>
        <img id="imgPreview" [hidden]="videoSelected" class="imgPreview" height="100%" src="{{selectedPhoto}}">
        <img [hidden]="photoSelected || videoSelected" class="noProfilePhoto_img" width="100%" height="100%"
          src="../../../../assets/images/default-profile-picture-new.svg">
          <div class="ui-popover openToggler">
            <button id="muteBtn" [hidden]="!videoSelected" (click)="muted(!muteClassAdd)"
              class="item-video-sound-control" [ngClass]="{'mute' : muteClassAdd}">
            <img class="audio-control-icon-img mute-icon"
              src="../../../../assets/images/sound-muted-icon.png"
              srcset="../../../../assets/images/sound-muted-icon@2x.png 2x, ../../../../assets/images/sound-muted-icon@3x.png 3x">
              <img class="audio-control-icon-img sound-icon"
                src="../../../../assets/images/sound-unmuted-icon.png"
                srcset="../../../../assets/images/sound-unmuted-icon@2x.png 2x, ../../../../assets/images/sound-ummuted-icon@3x.png 3x">
              </button>
            </div>
          </div>
          <div ngbDropdown class="camera-menu dropdown">
            <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="cameraMenuDropdown">
              <img src="../../../../assets/images/profile-camera-icon.png"
                srcset="../../../../assets/images/profile-camera-icon@2x.png 2x, ../../../../assets/images/profile-camera-icon@3x.png 3x"
                class="profile-camera-icon">
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="cameraMenuDropdown">
              <a ngbDropdownItem (click)="recordVideo()" class="dropdown-item">Take a Video</a>
            </div>
          </div>
        </div>
        <div class="photo-video-hint">
          Add a video talking about your major, activities, and achievements acquired at your school.
        </div>
      </div>
      <div class="popup-column-group form-container-column edit-profile-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-title"></h4>
        </div>
        <div class="modal-body">
          <input type="hidden" [(ngModel)]="pageStatus" value="{{pageStatus}}" />
          <div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">School<span class="requiredAsterix">*</span></label>
            <input inputRestriction="allChars" [ngClass]="{'error': showSchoolError}" (blur)="hideSchoolSuggestions()"
              maxlength="100" [(ngModel)]="school" type="" name="company"
              class="form-control unnanu-text-input-style-1" id="" value=""
              placeholder="Enter reference from list or add" [ngbTypeahead]="searchSchool"
              (selectItem)="schoolSelectedFn($event)" [resultTemplate]="rt" required (ngModelChange)="onChange($event)">
            <ng-template #rt let-r="result" let-t="term">
              <img src="{{r.LogoUrl || '../../../../assets/images/education-default.jpg'}}" class="mr-1"
                style="width: 16px">
              <ngb-highlight [result]="r.Name" [term]="t"></ngb-highlight>
            </ng-template>
            <label class="error" [hidden]="!showSchoolError">Enter School Name</label>
          </div>
          <div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">Location<span class="requiredAsterix">*</span></label>
            <city-suggestion inputRestriction="noSpecialChars" [data]="data" [parentobj]="parentobj" [showCityError]="showCityError" (cityUpdated)="onChange($event)">
            </city-suggestion>
            <label [hidden]="!parentobj.showCityError" class="error">Enter
            Location</label>
          </div>
          <div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">Degree<span class="requiredAsterix">*</span></label>
            <input inputRestriction="allChars" [ngClass]="{'error': showDegreeError}" maxlength="100" [(ngModel)]="degree"
              type="" name="" class="form-control unnanu-text-input-style-1" id="" value=""
              placeholder="Enter your degree from list or add" (blur)="hideDegreeSuggestions()"
              [ngbTypeahead]="searchDegree" (selectItem)="degreeSelectedFn($event)"
              [resultFormatter]="degreeFormatter" (ngModelChange)="onChange($event)">
            <label [hidden]="!showDegreeError" class="error">Enter
            Degree</label>
          </div>
          <div class="unnanu-form-group-style-1 d-flex justify-content-between">
            <div class="field-of-study-wrapper has-sussgestions-dropdown">
              <label class="unnanu-input-label-style-1">Field of Study<span
              class="requiredAsterix">*</span></label>
              <input inputRestriction="allChars" [ngClass]="{'error': showFOSError}" maxlength="100" [(ngModel)]="fos"
                type="" name="" class="form-control unnanu-text-input-style-1" id="" value=""
                placeholder="Enter your field of Study from list or add" (blur)="hideFOSSuggestions()"
                [ngbTypeahead]="searchFos" (selectItem)="fosSelectedFn($event)"
                [resultFormatter]="fosFormatter" (ngModelChange)="onChange($event)">
              <label [hidden]="!showDegreeError" class="error">Enter
              Field of Study</label>
            </div>
            <div class="">
              <label class="unnanu-input-label-style-1">GPA</label>
              <input [(ngModel)]="gpa" (keyup)="formatGPA()" type="number" maxlength="4" name="gpa"
                class="form-control unnanu-text-input-style-1" [ngClass]="{'error': showGPAError}" id=""
                value="" required (ngModelChange)="onChange($event)">
              <label class="error" [hidden]="!showGPAError">GPA is between 0 & 10</label>
              <label class="error" [hidden]="gpa>=0"> Leave ZERO or Enter GPA</label>
            </div>
          </div>
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">Time Period<span class="requiredAsterix">*</span></label>
            <div class="time-duration-wrapper d-flex">
              <div class="duration-time has-month">
                <div class="month-year-group d-flex justify-content-between">
                  <div class="year">
                    <select [(ngModel)]="startYear" class="form-control periodMenu yearMenu"
                      name="date_start[year]" required (ngModelChange)="onChange($event)">
                      <option value="Year:">Year</option>
                      @for (i of getArray(64); track i; ) {
                        <option
                          value="{{ currentYear - $index }}">
                        {{ currentYear - $index }}</option>
                      }
                    </select>
                  </div>
                  <div class="month">
                    <select class="form-control periodMenu yearMenu" name="date_start[year]" id="mySelect"
                      [(ngModel)]="startMonth.id" required (ngModelChange)="onChange($event)">
                      @for (option of data.months; track option) {
                        <option value="{{ option.id }}">
                        {{ option.name }}</option>
                      }
                    </select>
                  </div>
                </div>
                <label class="error" [hidden]="!startDateError">{{startDateErrorMessage}}</label>
                <label class="error" style="font-size: 11px !important;" [hidden]="!timePeriodError">{{timePeriodErrorMessage}}</label>
              </div>
              <div class="duration-to-label">-</div>
              <div class="duration-time duration-end-time has-month">
                <div class="month-year-group d-flex justify-content-between">
                  <div class="year">
                    <select [(ngModel)]="endYear" class="form-control periodMenu yearMenu" name="date_start[year]"
                      (ngModelChange)="onChange($event)">
                      <option value="Year:">Year</option>
                      @for (i of getArray(50); track i; ) {
                        <option
                          value="{{ maximumYear - $index }}">
                        {{ maximumYear - $index }}</option>
                      }
                    </select>
                  </div>
                  <div class="month">
                    <select class="form-control periodMenu yearMenu" name="date_start[year]" id="mySelect"
                      [(ngModel)]="endMonth.id" required (ngModelChange)="onChange($event)">
                      @for (option of data.months; track option) {
                        <option value="{{ option.id }}">
                        {{ option.name }}</option>
                      }
                    </select>
                  </div>
                </div>
                <label class="error" [hidden]="!endDateError">{{endDateErrorMessage}}</label>
              </div>
              <!-- End of the to time select dropdown -->
            </div>
            <div class="unnanu-form-group-style-1">
              <label class="unnanu-input-label-style-1">Description</label>
              <textarea [(ngModel)]="notes" rows="1" class="form-control unnanu-text-input-style-1"
              [ngClass]="{error: remaining() < 0}" maxlength="{{ maxLength }}" (ngModelChange)="onChange($event)"></textarea>
              @if (remaining() < 0) {
                <span>{{ remaining() }}</span>
              }
            </div>
            <span class="requiredAsterix">*</span>
            <label class="unnanu-input-label-style-1">Required Information </label>
          </div>
        </div>
        <div class="modal-footer text-right">
          <div class="footer-buttons-row d-flex justify-content-between align-items-center">
            @if (data.COGUID) {
              <button type="button" class="delete-card-button large float-left" (click)="deleteData()"
                >
                Delete
              </button>
            }
			@else {
				<button type="button" class="delete-card-button large float-left"
                  >

                </button>
			  }
			<div>
            <button type="button" class="cancel-changes-button large" (click)="close()">
              Cancel
            </button>
				<button type="button" class="save-changes-button large" [disabled]="!valueUpdated || !school || !degree || !fos
                    || !parentobj.city || !startYear || startYear === 'Year:' || startMonth.id === '0'
                    || !endYear || endYear === 'Year:' || endMonth.id === '0' || parentobj.showCityError
                    || hasToShowSchoolSuggestions || hasToShowDegreeSuggestions || hasToShowFOSSuggestions"
              (click)="saveData()">
              Save Changes
            </button>
		</div>
          </div>
        </div>
      </div>
    </div>
