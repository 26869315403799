<div class="modal-header">
	<h5 class="modal-title text-primary fw-bold" style="padding-left: 150px;">Enter Your Email</h5>
	<button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">
	<div style="margin-bottom: 19px;">{{msg}}</div>
	<form [formGroup]="emailForm" (ngSubmit)="submitEmail()">
	  <div class="mb-3">
		<input
		  type="email"
		  class="form-control"
		  formControlName="email"
		  placeholder="Enter your email"
		  style="padding: 0px;border: transparent;"
		/>
		<!-- <div *ngIf="emailForm.controls['email'].invalid && emailForm.controls['email'].touched" class="text-danger">
		  Please enter a valid email address.
		</div> -->
	  </div>
	  <div class="modal-footer" style="border: 0px;">
		<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cancel</button>
		<button type="submit" class="btn btn-primary" [disabled]="emailForm.invalid">Submit</button>
	  </div>
	</form>
  </div>
