// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	urls: {
		signalRURL_Obj: "https://uat-common-api-v5.unnanu.com/signalr/",
		messageUrl_Obj: "https://uat-common-api-v5.unnanu.com/api/v1/",
		facebookAppID: "138557023267771",
	//	facebookAppID: "295962714193867",
		serverUrl_Obj: "https://uat-recruit-api-v5.unnanu.com/api/v1/",
		ssnUrl_Obj: "https://uat-recruit-api-v5.unnanu.com/api/v1/",
		paymentUrl_Obj: "https://uat-payments-v5.unnanu.com/api/v1/",
		socketServerURL: "https://uat-realtime-v5.unnanu.com/",
		fileServerUrl_Obj: "https://cdn.unnanu.com",
		fileServerUrlHire_Obj: "https://cdn.unnanu.com",
		baseURL_Obj: window.location.origin,
		hire_url_obj: "https://uat-hire.unnanu.com",
		job_board: "https://uat.jobs.unnanu.com",
		landing_page: "https://uat.talent.unnanu.com",
		app_url_obj: "https://uat.app.unnanu.com",
		hire_landing_page: "https://uat-hire.unnanu.com"
	},
	keys: {
		ga_key: null
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
