<div class="enable-edit" *ngIf="!resumeName || enableUploadInEdit">
	<div class="modal-header">
		<h3 class="modal-title" id="modal-title" *ngIf="!editInProgress">Add Resume</h3>
		<h3 class="modal-title" id="modal-title" *ngIf="editInProgress">Edit Resume</h3>
	</div>
	<div class="modal-body">
		<p *ngIf="!editInProgress">Upload your current resume using the option below.</p>
		<p *ngIf="editInProgress">You can deactivate your current resume and upload a new one below.</p>

		<div class="uploading-wrapper" *ngIf="uploadAttachment">
			<label class="custom-file">
				<input id="file" class="custom-file-input" type="file" accept=".pdf,.txt,.doc,.docx,.html,.rtf,.odt"
					(change)="onFileChange($event)">
			</label>
			<div class="upload-button text-center">
				<svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20">
					<path fill="#000" fill-rule="nonzero"
						d="M23.442 7.5C22.596 3.25 18.97 0 14.5 0a8.81 8.81 0 0 0-7.975 5C2.779 5.5 0 8.625 0 12.5 0 16.625 3.263 20 7.25 20h15.708C26.342 20 29 17.25 29 13.75c0-3.25-2.538-6-5.558-6.25zm-6.525 3.75v5h-4.834v-5H8.458L14.5 5l6.042 6.25h-3.625z"
						opacity=".6" />
				</svg>Upload Resume
			</div>
		</div>
		<div class="uploading-wrapper uploading" *ngIf="uploading">
			<p class="text-center"> Uploading...</p>
		</div>
		<div class="uploading-wrapper uploaded" *ngIf="showUploaded && !uploading">
			<p class="">
				{{fileName | ellipsis:30}}'s Current Resume
			</p>
			<span class="dlt" [hidden]="resumeName" (click)="clearAttachment()"></span>
		</div>
		<p class="size">File size: 2MB or less | File types: 'doc', 'docx', 'txt', .rtf, .odt, .html and 'pdf'.</p>
	</div>
	<div class="modal-footer text-right" *ngIf="!resumeName || (enableUploadInEdit && !hideSaveButton)">
		<button class="cancel-changes-button large" (click)="closeThisDialog()">Cancel</button>
		<button class="save-changes-button large" [disabled]="disableSave" (click)="save()">Save</button>
	</div>
</div>

<div class="col-xs-12 enable-wrap" *ngIf="resumeName">
	<div class="modal-header" *ngIf="!enableUploadInEdit">
		<h3 class="modal-title" id="modal-title">Edit Resume</h3>
	</div>
	<div class="modal-body" *ngIf="!enableUploadInEdit">
		<p>You can deactivate your current resume and upload a new one below.</p>
		<div class="uploading-wrapper uploaded">
			<div class="">
				{{fileName | ellipsis:30}}'s Current Resume
			</div>
			<span class="dlt"
				(click)="enableDelete = true;enableUploadInEdit = true;hideSaveButton = true;editInProgress=true"></span>
		</div>
		<p class="size">File size: 2MB or less | File types: 'doc', 'docx', 'txt', .rtf, .odt, .html and 'pdf'.</p>
	</div>
	<div class="modal-footer">
		<div class="upload-new-file-button large float-left" [hidden]="enableDelete"
			(click)="enableUploadInEdit = true;hideSaveButton = true;uploadAttachment = false;showUploaded = true;editInProgress= true">
			<button class="btn btn-save float-left btn-upload" [disabled]="uploading">+ Resume</button>
			<input [disabled]="uploading" id="file" class="custom-file-input" type="file"
				accept=".pdf,.txt,.doc,.docx,.html,.rtf,.odt" (change)="onFileChange($event)">
		</div>
		<button class="save-changes-button large float-right" [hidden]="enableDelete" [disabled]="!enableSave"
			(click)="update()">Save</button>
		<button class="save-changes-button large float-right" [hidden]="!enableDelete" (click)="delete()">Save</button>
		<button class="cancel-changes-button large float-right" (click)="closeThisDialog()">Cancel</button>
	</div>
</div>
