import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { FooterComponent } from './footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [HeaderComponent, RouterOutlet, FooterComponent],
})
export class AppComponent implements OnInit {
	title = 'talent-web-app';

	constructor(private _ga: GoogleAnalyticsService) {}

	ngOnInit(): void {
		this._ga.initialize();
	}
}
