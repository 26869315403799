import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';

@Component({
    selector: 'remove-photo-alert',
    templateUrl: './remove-photo-alert.component.html',
    styleUrls: ['./remove-photo-alert.component.scss'],
    standalone: true
})
export class RemovePhotoAlertComponent {

	constructor(public modal: NgbActiveModal,
		private eventService: EventService) { }

	confirm() {
		this.eventService.broadcast('remove-media', {});
		this.modal.close();
	}

	cancel() {
		this.modal.dismiss();
	}

}
