import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { CommonAlertComponent } from '../common-alert/common-alert.component';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Options, ChangeContext, NgxSliderModule } from '@angular-slider/ngx-slider';
import { PhotoCaptureModalComponent } from '../photo-capture-modal/photo-capture-modal.component';
import { VideoRecordModalComponent } from '../video-record-modal/video-record-modal.component';
import { ConfirmAlertComponent } from '../confirm-alert/confirm-alert.component';
import { CitySuggestionComponent } from '../city-suggestion/city-suggestion.component';
import { InputRestrictionDirective } from '../../directives/input-restriction.directive';
import { FormsModule } from '@angular/forms';
import { NgClass, DecimalPipe } from '@angular/common';
import { FileSizeExceedAlertComponent } from '../file-size-exceed-alert/file-size-exceed-alert.component';

@Component({
    selector: 'edit-profile-modal',
    templateUrl: './edit-profile-modal.component.html',
    styleUrls: ['./edit-profile-modal.component.scss'],
    standalone: true,
    imports: [NgClass, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, FormsModule, InputRestrictionDirective, NgbTypeahead, NgxSliderModule, CitySuggestionComponent, DecimalPipe]
})
export class EditProfileModalComponent implements OnInit, AfterViewInit {

	@Input() data: any;
	@Input() basicInfo: any;
	@Output() updateProfile: EventEmitter<any> = new EventEmitter<any>();

	private videoData: any;
	private imageData: any;
	private citySelected: boolean = false;
	private oldData: any;

	public posSelected: boolean = false;
	public muteClassAdd: boolean = true;
	public videoEditted: boolean = false;
	public photoEditted: boolean = false;

	public minValue: number = 8;
	public maxValue: number = 200;
	public firstName: string = '';
	public lastName: string = '';
	public showPositionError: boolean = false;
	public positionErrorMessage: string = '';
	public showCityError: boolean = false;
	public cityErrorMessage: string = '';
	public showFirstNameError: boolean = false;
	public showLastNameError: boolean = false;
	public AvailabilityError: boolean = false;
	public position: string = '';
	public optionalPosition1: string = '';
	public optionalPosition2: string = '';
	public citystring = '';
	public workAuthError: boolean = false;
	public workAuth: string = '1';
	public Availability: string = '7';
	public willRelocate: boolean = false;
	public sharePicture: boolean = false;

	public id: any;
	public positionId: any;
	public optionalPositionId1: any;
	public optionalPositionId2: any;
	public city: any;
	public selectedPhoto: any;
	public photoSelected: boolean = false;
	public selectedVideoUrl: any;
	public videoSelected: boolean = false;
	public intern: boolean = false;
	public contract: boolean = false;
	public fullTime: boolean = false;
	public Volunteer: boolean = false;
	public showAddtolist: boolean = true;
	public suggestions: any[] = [];
	public showpositionAddtolist: boolean = true;
	public firstNameSelected: boolean = false;
	public lastNameSelected: boolean = false;
	public rawVideoUrl: string = '';

	public parentobj: any = {
		position: '',
		city: '',
		showPositionError: '',
		showCityError: ''
	};

	public salarySlider = {
		minValue: this.minValue,
		maxValue: this.maxValue,
		options: {
			floor: 8,
			ceil: 600,
			step: 1,
			minLimit: 8,
			maxLimit: 600
		} as Options,
		onChange: (changeContext: ChangeContext) => {
			this.minValue = changeContext.value;
			this.maxValue = changeContext.highValue || 0;
			this.valueUpdated = true;
		}
	};

	public valueUpdated: boolean = false;

	constructor(public modal: NgbActiveModal,
		private sanitizer: DomSanitizer,
		private autocompleteService: AutocompleteService,
		private eventService: EventService,
		private modalService: NgbModal) { }

	ngOnInit() {
		if (this.basicInfo) {
			let obj = this.basicInfo;
			this.oldData = this.basicInfo;
			this.id = obj.UserId;
			this.firstName = obj.FirstName;
			this.lastName = obj.LastName;
			this.positionId = obj.JobTitleId;
			this.position = obj.JobTitle;
			this.optionalPositionId1 = obj.optionalTitleId1;
			this.optionalPosition1 = obj.JobTitle1;
			this.optionalPositionId2 = obj.optionalTitleId2;
			this.optionalPosition2 = obj.JobTitle2;
			this.maxValue = obj.EndPay;
			this.minValue = obj.StartPay;

			this.salarySlider.minValue = this.minValue;
			this.salarySlider.maxValue = this.maxValue;

			this.city = obj.Location;
			this.workAuth = obj.WorkType.toString();
			this.Availability = obj.Availability ? obj.Availability.toString() : '-1';
			this.willRelocate = obj.WillingToRelocate;
			this.sharePicture = obj.shareProfilePic;
			if (obj.isDPVideo === 0) {
				this.selectedPhoto = obj.DpUrl;
				this.photoSelected = true;
			} else {
				this.selectedVideoUrl = obj.DpUrl;
				this.videoSelected = true;
			}

			obj.JobTypes.forEach((value: number) => {
				if (value === 0) {
					this.intern = true;
				} else if (value === 1) {
					this.contract = true;
				} else if (value === 2) {
					this.fullTime = true;
				} else if (value === 3) {
					this.Volunteer = true;
				}
			});
			this.valueUpdated = true;
		}

		this.eventService.subscribe('video-selected', (value) => {
			// video selected
			this.muteClassAdd = true;
			this.videoEditted = true;
			this.photoSelected = false;
			this.videoSelected = true;
			this.selectedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(value.data.url);
			this.rawVideoUrl = value.data.url;
			this.videoData = value.data.videoData;
			if (document.getElementById('muteBtn')) {
				this.muted(true);
			}
			this.valueUpdated = true;
		});

		this.eventService.subscribe('photo-selected', (value) => {
			this.photoEditted = true;
			this.videoSelected = false;
			this.photoSelected = true;
			this.selectedPhoto = value.data;
			this.imageData = value.data;
			this.muted(true);
			this.valueUpdated = true;
		});
	}

	ngAfterViewInit() {
		this.muted(true);
	}

	muted(val: boolean) {
		if (document.getElementById('vidPreview')) {
			if (val !== undefined && val !== null) {
				(document.getElementById('vidPreview') as HTMLMediaElement).muted = val;
				this.muteClassAdd = val;
			}
		}
	}

	positionVideo() {
		let selectedVideo = (document.querySelector('#vidPreview') as HTMLElement);
		let leftMargin = ((selectedVideo.offsetWidth - selectedVideo.offsetHeight) / 2) * -1;
		(document.getElementById('vidPreview') as HTMLElement).style.marginLeft = `${leftMargin}px`;
		if (!this.videoEditted) {
			this.muted(true);
		}
	}

	positionPhoto() {
		let selectedVideo = (document.querySelector('.imgPreview') as HTMLElement);
		let leftMargin = ((selectedVideo.offsetWidth - selectedVideo.offsetHeight) / 2) * -1;
		(document.getElementById('imgPreview') as HTMLElement).style.marginLeft = `${leftMargin}px`;
	}

	roundSalRange(val: number) {
		return Math.round((((val * 8 * 20 * 12) / 1000) * 10) / 10);
	}


	recordVideo() {
		this.muteClassAdd = true;
		this.muted(true);
		let modal = this.modalService.open(VideoRecordModalComponent,
			{ windowClass: 'modal-theme-unnanu-v2 video-recorder-popup', backdrop: 'static', keyboard: false });
	}


	takePhotoDialog() {
		this.muted(true);
		let modal = this.modalService.open(PhotoCaptureModalComponent,
			{ windowClass: 'modal-theme-unnanu-v2 video-recorder-popup', backdrop: 'static', keyboard: false });
	}

	filePicked(event: Event) {
		const inputElement = event.target as HTMLInputElement;

		if (inputElement.files && inputElement.files.length > 0) {
		  const file = inputElement.files[0];

		  // Ensure the file is a valid JPEG image
		  if (file.type !== 'image/jpeg') {
			let modal = this.modalService.open(FileSizeExceedAlertComponent, { windowClass: 'alert-popup-common', backdrop: 'static', keyboard: false });
						modal.componentInstance.msg = 'Only JPEG images are allowed!';
			inputElement.value = ''; // Reset input field
			return;
		  }

		  const reader = new FileReader();

		  reader.onload = () => {
			this.selectedPhoto = reader.result as string;
			this.imageData = reader.result as string;
			this.positionSelectedImage();
		  };

		  reader.readAsDataURL(file);

		  this.selectedPhoto = URL.createObjectURL(file);
		  this.photoSelected = true;
		  this.videoSelected = false;
		  this.photoEditted = true;
		  this.imageData = this.selectedPhoto;

		  inputElement.value = ''; // Reset the file input after processing
		  this.muted(true);
		}
	  }

	positionSelectedImage(val?: any) {
		if (val === undefined) {
			val = 1;
		}
		if (val !== 0) {
			let parent = (document.querySelector('.photo-video-container') as HTMLElement);
			let selectedImg = (document.querySelector('.capturePhoto') as HTMLElement);

			let leftMargin;
			if (selectedImg !== null) {
				leftMargin = ((parent.offsetWidth - selectedImg.offsetWidth) / 2) * val;
			} else {
				leftMargin = (parent.offsetWidth / 2) * val;
			}

			if (document.getElementById('capturePhoto') !== null) {
				(document.getElementById('capturePhoto') as HTMLElement).style.marginLeft = `${leftMargin}px`;
			}
		} else {
			(document.getElementById('capturePhoto') as HTMLElement).style.marginLeft = '-21px';
		}
	}

	suggestPositions(term: string) {
		this.validatePosition();
		if (term.length >= 3) {
			this.showAddtolist = false;
			return this.autocompleteService.get(term, 'profession').pipe(map((response: any) => {
				if (
					response.status === 200 &&
					response.Code === 200 &&
					response.Data.length > 0
				) {
					this.suggestions = response.Data;
					return this.suggestions;
				} else {
					// Add New Position
					this.suggestions = [];
					return this.suggestions;
				}
			})
			);
		} else {
			this.showAddtolist = false;
			return of([]);
		}
	}

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestPositions(term))
		)

	formatter = (result: any) => result.Name;

	positionSelected(event: any) {
		event.preventDefault();
		let positionName = event.item.Name;
		let id = event.item.Id;
		if (positionName !== '') {
			this.posSelected = true;
			this.position = positionName;
			this.positionId = id;
			this.showAddtolist = false;
			this.showpositionAddtolist = false;
		} else {
			this.position = '';
		}
		this.showpositionAddtolist = false;
	}

	search1: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestPositions(term))
		)

	opPositionSelected1(event: any) {
		event.preventDefault();
		let positionName = event.item.Name;
		let id = event.item.Id;
		if (positionName !== '') {
			this.optionalPosition1 = positionName;
			this.optionalPositionId1 = id;
		} else {
			this.optionalPosition1 = '';
		}
	}

	search2: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestPositions(term))
		)

	opPositionSelected2(event: any) {
		event.preventDefault();
		let positionName = event.item.Name;
		let id = event.item.Id;
		if (positionName !== '') {
			this.optionalPosition2 = positionName;
			this.optionalPositionId2 = id;
		} else {
			this.optionalPosition2 = '';
		}
	}

	validateFirstName() {
		if (this.firstName.length > 0) {
			this.firstNameSelected = true;
			this.showFirstNameError = false;
		} else {
			this.firstNameSelected = false;
			this.showFirstNameError = true;
		}
	}

	validateLastName() {
		if (this.lastName.length > 0) {
			this.lastNameSelected = true;
			this.showLastNameError = false;
		} else {
			this.lastNameSelected = false;
			this.showLastNameError = true;
		}
	}

	validatePosition() {
		if (this.parentobj.position === '' && this.position === '') {
			this.positionErrorMessage = 'Please select your position';
			this.showPositionError = true;
		} else {
			this.showPositionError = false;
		}
	}

	validateCity() {
		if (this.parentobj.city === '' && this.city === '') {
			this.cityErrorMessage = 'Please select a City';
			this.showCityError = true;
		} else {
			this.showCityError = false;
		}
	}

	validateWorkAuth() {
		if (this.workAuth !== '-1') {
			this.workAuthError = false;
		} else {
			this.workAuthError = true;
		}
	}

	validateAvailability() {
		if (this.Availability !== '-1') {
			this.AvailabilityError = false;
		} else {
			this.AvailabilityError = true;
		}
	}

	saveData() {
		this.validateFirstName();
		this.validateLastName();
		this.validateWorkAuth();
		this.validateAvailability();

		if (
			this.workAuthError ||
			this.AvailabilityError ||
			this.showFirstNameError ||
			this.showLastNameError ||
			this.parentobj.showPositionError ||
			this.parentobj.showCityError ||
			!(this.fullTime || this.intern || this.contract || this.Volunteer)
		) {
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.msg = 'Fill all required fields.';
		} else {
			let jobTypes = [];
			if (this.fullTime) jobTypes.push(2);
			if (this.contract) jobTypes.push(1);
			if (this.intern) jobTypes.push(0);
			if (this.Volunteer) jobTypes.push(3);

			let objToUpload = {
				UpdateRecords: [
					{
						Type: 'BASIC',
						Data: {
							FirstName: this.firstName,
							LastName: this.lastName,
							FullName: `${this.firstName} ${this.lastName}`,
							UserId: this.id,
							JobTitleId:
								this.parentobj.position !== ''
									? this.parentobj.position
									: this.position,
							JobTitle1: this.optionalPosition1,
							JobTitle2: this.optionalPosition2,
							DpUrl: '',
							StartPay: this.minValue,
							EndPay: this.maxValue,
							JobTypes: jobTypes,
							Availability: this.Availability,
							WorkType: this.workAuth,
							Location:
								this.parentobj.city !== ''
									? this.parentobj.city
									: this.city,
							WillingToRelocate: this.willRelocate,
							shareProfilePic: this.sharePicture,
						},
					},
				],
			};

			if (this.videoEditted) {
				this.updateProfile.emit({ objToUpload: objToUpload, isVideo: true, isMediaModified: true, mediaData: this.videoData });
				this.basicInfo.isDPVideo = 1;
				this.basicInfo.DpUrl = this.selectedVideoUrl;
			} else if (this.photoEditted) {
				this.updateProfile.emit({ objToUpload: objToUpload, isVideo: false, isMediaModified: true, mediaData: this.imageData });
				this.basicInfo.isDPVideo = 0;
				this.basicInfo.DpUrl = this.selectedPhoto;
			} else if (!this.sharePicture){
				this.basicInfo.isDPVideo = 0;
				this.basicInfo.DpUrl = '../../../assets/images/default-profile-picture-thumb.svg';
				this.updateProfile.emit({ objToUpload: objToUpload, isVideo: false, isMediaModified: false, mediaData: undefined });
			}
			else {
				this.updateProfile.emit({ objToUpload: objToUpload, isVideo: false, isMediaModified: false, mediaData: undefined });
			}

			this.basicInfo.EndPay = this.maxValue;
			this.basicInfo.FirstName = this.firstName;
			this.basicInfo.LastName = this.lastName;
			this.basicInfo.FullName = `${this.firstName} ${this.lastName}`;
			this.basicInfo.JobTitle =
				this.parentobj.position !== ''
					? this.parentobj.position
					: this.position;
			this.basicInfo.JobTitle1 = this.optionalPosition1;
			this.basicInfo.JobTitle2 = this.optionalPosition2;
			this.basicInfo.JobTitleId = this.positionId;
			this.basicInfo.optionalTitleId1 = this.optionalPositionId1;
			this.basicInfo.optionalTitleId2 = this.optionalPositionId2;
			this.basicInfo.JobTypes = jobTypes;
			this.basicInfo.Location =
				this.parentobj.city !== '' ? this.parentobj.city : this.city;
			this.basicInfo.StartPay = this.minValue;
			this.basicInfo.WorkType = this.workAuth;
			this.basicInfo.Availability = this.Availability;
			this.basicInfo.WillingToRelocate = this.willRelocate;
			this.basicInfo.shareProfilePic = this.sharePicture,
			this.close();
		}
	}

	onChange(event: any){
		this.valueUpdated = true;
	}

	onChangeShare(checked: boolean) {
    if (!checked) {
      let modal = this.modalService.open(ConfirmAlertComponent, {backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Remove profile video or picture:';
			modal.componentInstance.msg = 'This will remove your profile video or picture from your profile. You will need to re-record or upload a new picture. Are you sure you want to proceed?';
			modal.result.then((closed) => {
				this.sharePicture = false;
				this.selectedPhoto = '';
				this.photoSelected = false;
				this.selectedVideoUrl = '';
				this.videoSelected = false;
			},
				(dismissed) => {
					this.sharePicture = true;
				});
    }
		this.valueUpdated = true;
	}

	close() {
		this.modal.dismiss();
	}


	deleteImage() {
		this.selectedPhoto = '';
		this.photoSelected = false;
		this.photoEditted = true;
		this.imageData = null;
		this.valueUpdated = true;
		this.sharePicture=false


		this.muted(true);

	  }

}
