import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'cancel-subscripted-alert',
    templateUrl: './cancel-subscripted-alert.component.html',
    styleUrls: ['./cancel-subscripted-alert.component.scss'],
    standalone: true
})
export class CancelSubscriptedAlertComponent {

	constructor(public modal: NgbActiveModal) { }

	deactivate() {
		this.modal.close();
	}

	cancel() {
		this.modal.dismiss();
	}

}
