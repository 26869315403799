import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'common-alert',
    templateUrl: './common-alert.component.html',
    styleUrls: ['./common-alert.component.scss'],
    standalone: true
})
export class CommonAlertComponent {

	@Input() header: string = 'Information';
	@Input() msg: string = '';
	@Input() contentType = 'Text'
	@Input() showCancelButton: boolean = false;

	constructor(public modal: NgbActiveModal) { }


	ok() {
		this.modal.close('OK'); // Close the modal and pass 'OK' as the result
	}

	// Method for the "Cancel" button
	cancel() {
		this.modal.dismiss('Cancel'); // Dismiss the modal and pass 'Cancel' as the result
	}
}
