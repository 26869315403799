import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { AddResumeAttachmentModalComponent } from './components/add-resume-attachment-modal/add-resume-attachment-modal.component';
import { AddResumesModalComponent } from './components/add-resumes-modal/add-resumes-modal.component';
import { CameraComponent } from './components/camera/camera.component';
import { CitySuggestionComponent } from './components/city-suggestion/city-suggestion.component';
import { CommonAlertComponent } from './components/common-alert/common-alert.component';
import { ConfirmAlertComponent } from './components/confirm-alert/confirm-alert.component';
import { EditProfileModalComponent } from './components/edit-profile-modal/edit-profile-modal.component';
import { FileSizeExceedAlertComponent } from './components/file-size-exceed-alert/file-size-exceed-alert.component';
import { FileViewModalComponent } from './components/file-view-modal/file-view-modal.component';
import { PhotoCaptureModalComponent } from './components/photo-capture-modal/photo-capture-modal.component';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { VideoRecordModalComponent } from './components/video-record-modal/video-record-modal.component';
import { ThirtySecondsVideoRecordModalComponent } from './components/thirty-seconds-video-record-modal/thirty-seconds-video-record-modal.component';
import { AttachmentViewDirective } from './directives/attachment-view.directive';
import { AutoExpandTextDirective } from './directives/auto-expand-text.directive';
import { CheckListDirective } from './directives/check-list.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { AddCertificationModalComponent } from './components/add-certification-modal/add-certification-modal.component';
import { CertificationVideoComponent } from './components/certification-video/certification-video.component';
import { AddExperienceModalComponent } from './components/add-experience-modal/add-experience-modal.component';
import { ExperienceVideoComponent } from './components/experience-video/experience-video.component';
import { AddEducationModalComponent } from './components/add-education-modal/add-education-modal.component';
import { EducationVideoComponent } from './components/education-video/education-video.component';
import { InviteModalComponent } from './components/invite-modal/invite-modal.component';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { OnboardingModalComponent } from './components/onboarding-modal/onboarding-modal.component';
import { RescheduleModalModalComponent } from './components/reschedule-interview-modal/reschedule-interview-modal.component';
import { CancelSubscriptedAlertComponent } from './components/cancel-subscripted-alert/cancel-subscripted-alert.component';
import { NgxMaskModule } from 'ngx-mask';
import { PhoneConfirmationModalComponent } from './components/phone-confirmation-modal/phone-confirmation-modal.component';
import { CodeInputModule } from 'angular-code-input';
import { RemovePhotoAlertComponent } from './components/remove-photo-alert/remove-photo-alert.component';
import { InputRestrictionDirective } from './directives/input-restriction.directive';
import { ToastrModule } from 'ngx-toastr';
import { TimeagoModule } from 'ngx-timeago';

@NgModule({
	declarations: [
		SocialLoginComponent,
		CommonAlertComponent,
		ConfirmAlertComponent,
		FileViewModalComponent,
		AddResumeAttachmentModalComponent,
		AddResumesModalComponent,
		FileSizeExceedAlertComponent,
		EditProfileModalComponent,
		CitySuggestionComponent,
		CameraComponent,
		PhotoCaptureModalComponent,
		VideoRecordModalComponent,
		ThirtySecondsVideoRecordModalComponent,
		AddCertificationModalComponent,
		CertificationVideoComponent,
		AddExperienceModalComponent,
		ExperienceVideoComponent,
		AddEducationModalComponent,
		EducationVideoComponent,
		InviteModalComponent,
		OnboardingModalComponent,
		RescheduleModalModalComponent,
		CancelSubscriptedAlertComponent,
		PhoneConfirmationModalComponent,
		RemovePhotoAlertComponent,
		AutoExpandTextDirective,
		CheckListDirective,
		AttachmentViewDirective,
		EllipsisPipe,
		FilterPipe,
		OrderByPipe,
		InputRestrictionDirective
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		TagInputModule,
		NgxSliderModule,
		NgAddToCalendarModule,
		NgxMaskModule.forRoot(),
		CodeInputModule,
		ToastrModule.forRoot({positionClass: 'toast-top-center', toastClass: "custom-toastr ngx-toastr"}),
		TimeagoModule.forChild()
	],
	exports: [
		CommonModule,
		FormsModule,
		NgbModule,
		TagInputModule,
		ReactiveFormsModule,
		NgxSliderModule,
		NgAddToCalendarModule,
		NgxMaskModule,
		CodeInputModule,
		ToastrModule,
		TimeagoModule,
		SocialLoginComponent,
		CommonAlertComponent,
		ConfirmAlertComponent,
		FileViewModalComponent,
		AddResumeAttachmentModalComponent,
		AddResumesModalComponent,
		FileSizeExceedAlertComponent,
		EditProfileModalComponent,
		CitySuggestionComponent,
		CameraComponent,
		PhotoCaptureModalComponent,
		VideoRecordModalComponent,
		ThirtySecondsVideoRecordModalComponent,
		AddCertificationModalComponent,
		CertificationVideoComponent,
		AddExperienceModalComponent,
		ExperienceVideoComponent,
		AddEducationModalComponent,
		EducationVideoComponent,
		InviteModalComponent,
		OnboardingModalComponent,
		RescheduleModalModalComponent,
		CancelSubscriptedAlertComponent,
		PhoneConfirmationModalComponent,
		RemovePhotoAlertComponent,
		AutoExpandTextDirective,
		CheckListDirective,
		AttachmentViewDirective,
		EllipsisPipe,
		FilterPipe,
		OrderByPipe,
		InputRestrictionDirective
	],
	providers: [
		EllipsisPipe,
		FilterPipe,
		OrderByPipe
	]
})
export class SharedModule { }
