import { HttpClient } from '@angular/common/http';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { EventService } from '../../../core/services/event.service';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonAlertComponent } from '../common-alert/common-alert.component';
import { SocketIOService } from 'src/app/core/services/socket-io.service';

@Component({
	selector: 'social-login',
	templateUrl: './social-login.component.html',
	styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit, OnDestroy {

	@Input() showOr: boolean = true;

	public isAeSigningUp: boolean = false;
	public isGeSigningUp: boolean = false;
	public isFbSigningUp: boolean = false;

	private user: any = {};

	private googleSignInData: any = {
		gid: '',
		gname: '',
		gemail: '',
		gfname: '',
		glname: ''
	}

	private appleSigninData: any = {
		aid: '',
		email: '',
		firstName: '',
		lastName: ''
	}

	constructor(private http: HttpClient,
		private zone: NgZone,
		private userService: UserService,
		private socketIOService: SocketIOService,
		private eventService: EventService,
		private modalService: NgbModal) { }

	ngOnInit() {
		(window as any).fbAsyncInit = function () {
			FB.init({
				appId: environment.urls.facebookAppID,
				xfbml: true,
				version: 'v2.8'
			});
		};

		// load facebook sdk script
		(function (d, s, id) {
			let js: any, fjs: any = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));

		// Used for social login is user exists
		this.eventService.subscribe('user-signed-in', (payload: any) => {
			this.userSignIn(payload);
		});

	}

	ngOnDestroy() {

	}

	// Only used for social login
	private userSignIn(args: any) {
		this.userService.setCookie('token', args.response.Data.Token);
		this.userService.setCookie('stage', args.response.Data.Stage);
		this.userService.setCookie('user_id', args.response.Data.User_id);
		this.userService.setCookie('stageToRedirect', args.response.Data.Stage_To_Redirect);
		// connect messaging service
		if (this.socketIOService.getConnectionStatus() === false ||
			this.socketIOService.getConnectionStatus() === undefined) {
			this.socketIOService.connect();
		}

		if (Number(args.response.Data.Stage) === 5) {
			this.userService.profileInfo().subscribe((response: any) => {
				if (response.status === 200 && response.Code === 200) {
					console.log(response);
					this.eventService.broadcast('ShowResumePopup', response);
				}
			});
		}

		this.userService.redirectUser(Number(args.response.Data.Stage), true);
	}

	applePressed() {
		this.isAeSigningUp = true;
		this.isGeSigningUp = false;
		this.isFbSigningUp = false;
		AppleID.auth.init({
			clientId: 'com.unnanu.app.service',
			redirectURI: `${environment.urls.app_url_obj}/login`,
			usePopup: true,
			scope: 'name email',
			state: 'UnnanuApp',
		})
		let data = AppleID.auth.signIn();
		//Listen for authorization success
		document.addEventListener('AppleIDSignInOnSuccess', (data: any) => {
			console.log('AppleIDSignInOnSuccess - Success');
			let decoded: any = jwt_decode(data.detail.authorization.id_token);
			this.appleSigninData.aid = decoded.sub;
			this.appleSigninData.email = decoded.email;
			if (data.detail.user != undefined) {
				this.appleSigninData.firstName = data.detail.user.name.firstName;
				this.appleSigninData.lastName = data.detail.user.name.lastName;
			} else {
				let nameSplitted = decoded.email.split('@');
				this.appleSigninData.firstName = nameSplitted[0];
				this.appleSigninData.lastName = 'AppleWS';
			}
			this.aeAuthenticate(this.appleSigninData);
		});
		//Listen for authorization failures
		document.addEventListener('AppleIDSignInOnFailure', (error) => {
			console.log('AppleIDSignInOnFailure', error);
			this.isAeSigningUp = false;
		});
	}

	private aeAuthenticate(data: any) {
		if (data.email) {
			this.userService.appleAuth(
				{
					email: data.email,
					token: data.aid,
					password: '',
				}).subscribe((response: any) => {
					if (response.status === 200 && response.Code === 401) {
						// user has not signed up before so sign him in
						let user = {
							email: data.email,
							firstName: data.firstName,
							lastName: data.lastName,
							password: '',
							token: data.aid || ''
						};
						this.isAeSigningUp = true;
						this.user = user;
						this.userService.signUp(this.user, 'APPLE').subscribe();
					} else if (response.status === 200 && response.Code === 200) {
						this.userService.setCookie('userEmail', data.email);
						this.userService.setCookie('user_id', response.Data.User_id);
						this.eventService.broadcast('user-signed-in', { response: response });
					} else {
						this.isAeSigningUp = false;
					}
				}
				);
		} else {
			this.isAeSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Apple login again';
			modal.componentInstance.msg = 'We are unable to authenticate your account at Apple. Try again now or try after updating Apple account settings.';
		}
	}

	googlePressed() {
		this.isAeSigningUp = false;
		this.isGeSigningUp = true;
		this.isFbSigningUp = false;
		gapi.auth2.authorize({
			client_id: '179779178843-cik41cd628og1meleo75rlamd6kr0cm4.apps.googleusercontent.com',
			scope: 'email profile openid',
			response_type: 'id_token permission'
		}, (response) => {
			if (response.error) {
				console.log('Sorry, Google SignIn Error... try again.');
				this.isGeSigningUp = false;
				return;
			}
			this.http.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${response.access_token}`)
				.subscribe((response: any) => {
					this.googleSignInData = {
						gid: response.id,
						gname: response.name,
						gemail: response.email,
						gfname: response.given_name,
						glname: response.family_name
					}
					this.zone.run(() => {
						this.geAuthenticate(this.googleSignInData);
					});
				},
					(error) => {
						console.log('*** Sorry, Google SignIn Error... try again ****');
						this.isGeSigningUp = false;
						console.log(error);
					});
		});
	}

	private geAuthenticate(data: any) {
		if (data.gemail) {
			this.userService.googleAuth({
				email: data.gemail,
				token: data.gid,
				password: '',
			}).subscribe((response: any) => {
				if (response.status === 200 && response.Code === 401) {
					let user = {
						email: data.gemail,
						firstName: data.gfname,
						lastName: data.glname,
						password: '',
						token: data.gid || '',
					};
					this.isGeSigningUp = true;
					this.user = user;
					this.userService.signUp(this.user, 'GOOGLE').subscribe();
				} else if (response.status === 200 && response.Code === 200) {
					this.userService.setCookie('userEmail', data.gemail);
					this.userService.setCookie('user_id', response.Data.User_id);
					this.eventService.broadcast('user-signed-in', { response: response });
				} else {
					this.isGeSigningUp = false;
				}
			});
		} else {
			this.isGeSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Google login again';
			modal.componentInstance.msg = 'Sorry, We are unable to authenticate with Google. Try after updating Google account settings.';
		}
	}

	facebookPressed() {
		this.isAeSigningUp = false;
		this.isGeSigningUp = false;
		this.isFbSigningUp = true;
		FB.getLoginStatus((response) => {
			this.zone.run(() => {
				this.statusChangeCallback(response);
			});
		});
	}

	private statusChangeCallback(response: any) {
		// The response object is returned with a status field that lets the
		// app know the current login status of the person.
		// Full docs on the response object can be found in the documentation
		// for FB.getLoginStatus().

		let fbToken: any = null;
		if (response.authResponse) {
			fbToken = response.authResponse.accessToken;
		}
		if (response.status === 'connected') {
			// Logged into your app and Facebook.
			let url = fbToken ? '/me?fields=email,name&access_token='+{fbToken} : '/me?fields=email,name';
			FB.api(url, (res: any) => {
				this.zone.run(() => {
					this.fbAuthenticate(res.name, res.email, fbToken);
				});
			});
		} else if (response.status === 'not_authorized') {
			// The person is logged into Facebook, but not your app.
			this.fbAPI();
		} else if (response.status === 'unknown') {
			// The person is logged into Facebook, but not your app.
			this.fbAPI();
		} else {
			// The person is not logged into Facebook, so we're not sure if
			// they are logged into this app or not.
			this.fbAPI();
		}
	}

	private fbAPI() {
		let fbToken: any = null;
		FB.login((response) => {
			if (response.authResponse) {
				fbToken = response.authResponse.accessToken;
			}
			let url = '/me?fields=email,name';
			FB.api(url, (res: any) => {
				FB.api(url, (res: any) => {
					this.zone.run(() => {
						this.fbAuthenticate(res.name, res.email, fbToken);
					});
				});
			});
		},
			{ scope: 'email' }
		); /* perms changed to scope */
	}

	private fbAuthenticate(name: any, email: any, fbToken: any) {
		if (!email)
		{ this.isFbSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Apologies, Facebook Email Not Found!';
			modal.componentInstance.msg = 'We were unable to authenticate your Facebook account or retrieve your email for Unnanu Talent. Please update your Facebook account settings and try again using the Continue with Facebook option.';}
		else if (email && name) {
			this.userService.facebookAuth(
				{
					email: email,
					token: fbToken,
					password: '',
				}).subscribe((response: any) => {
					if (response.status === 200 && response.Code === 401) {
						// user has not signed up before so sign him in
						let nameSplitted = name.split(' ');
						let user: any = {
							email: email,
							firstName: nameSplitted[0],
							lastName: nameSplitted[nameSplitted.length - 1],
							password: '',
							token: fbToken || '',
						};
						this.isFbSigningUp = true;
						this.user = user;
						this.userService.signUp(this.user, 'FACEBOOK').subscribe();
					} else if (response.status === 200 && response.Code === 200) {
						this.userService.setCookie('userEmail', email);
						this.userService.setCookie('user_id', response.Data.User_id);
						this.eventService.broadcast('user-signed-in', { response: response });
					} else {
						this.isFbSigningUp = false;
					}
				});
		} else {
			this.isFbSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Facebook login again';
			modal.componentInstance.msg = 'We are unable to authenticate your account at Facebook. Try again now or try after updating Facebook account settings.';
		}
	}

}
