<div class="edit-profile-dialog-content d-flex flex-row">
  <div class="popup-column-group profile-photo-video-column">
    <div class="column-title">Edit Profile</div>


    <div class="photo-video-ui">

		<!-- <span class="material-symbols-outlined" (click)="deleteImage()">
			delete
			</span> -->
      <div class="photo-video-container">
        <div class="profile-video-ui-wrapper">
          @if (videoSelected) {
            <video (onloadeddata)="positionVideo()" id="vidPreview"
            [src]="selectedVideoUrl" autoplay loop></video>
          }
        </div>
        <img id="imgPreview" [hidden]="videoSelected" class="imgPreview" height="100%" [src]="selectedPhoto">
        <img [hidden]="photoSelected || videoSelected" class="noProfilePhoto_img" width="100%" height="100%"
          src="../../../../assets/images/default-profile-picture-new.svg">
          <div class="ui-popover openToggler">
            <button id="muteBtn" [hidden]="!videoSelected" (click)="muted(!muteClassAdd)"
              class="item-video-sound-control" [ngClass]="{'mute' : muteClassAdd}">
            <img class="audio-control-icon-img mute-icon"
              src="../../../../assets/images/sound-muted-icon.png"
              srcset="../../../../assets/images/sound-muted-icon@2x.png 2x, ../../../../assets/images/sound-muted-icon@3x.png 3x">
              <img class="audio-control-icon-img sound-icon"
                src="../../../../assets/images/sound-unmuted-icon.png"
                srcset="../../../../assets/images/sound-unmuted-icon@2x.png 2x, ../../../../assets/images/sound-ummuted-icon@3x.png 3x">
              </button>
            </div>
          </div>
          <div ngbDropdown class="camera-menu dropdown">
            <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="cameraMenuDropdown">
              <img src="../../../../assets/images/profile-camera-icon.png"
                srcset="../../../../assets/images/profile-camera-icon@2x.png 2x, ../../../../assets/images/profile-camera-icon@3x.png 3x"
                class="profile-camera-icon">
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="cameraMenuDropdown">
              <a ngbDropdownItem (click)="recordVideo()" class="dropdown-item">Take a Video</a>
              <a ngbDropdownItem (click)="takePhotoDialog()" class="dropdown-item">Take a Photo</a>
              <div ngbDropdownItem class="dropdown-item upload-image">
                Upload a Photo
                <input id="uploadedImage" type="file" (change)="filePicked($event)" accept="image/jpeg">
              </div>
			  <a ngbDropdownItem (click)="deleteImage()" class="dropdown-item">Delete Photo or Video</a>
            </div>
          </div>

        </div>

        <div class="photo-video-hint">
          Update your profile video or picture. Talk about who you are, what you've done and what you would like to
          do.
          <p>Note: Records up to 60 seconds</p>
        </div>
        <div style="align-items: center; display: flex;">
          <span class="unnanu-check-box-style-1">
            <input [(ngModel)]="sharePicture" type="checkbox" id="shareProfilePic" name=""
              class="form-check" (ngModelChange)="onChangeShare($event)">
            <label for="shareProfilePic"></label>
          </span>
          <span style="padding-left: 20px;">Share My Picture</span><span class="requiredAsterix">*</span>
        </div>
      </div>
      <div class="popup-column-group form-container-column edit-profile-content">
		<div class="modal-header" style="padding: 20px 0px;">
			<h4 class="modal-title" id="modal-title"></h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
		  </div>

        <div class="modal-body">
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">First Name<span class="requiredAsterix">*</span></label>
            <input inputRestriction='lettersOnly' [ngClass]="{'error': showFirstNameError}" (blur)="validateFirstName()" maxlength="60"
              (change)="validateFirstName()" [(ngModel)]="firstName" type="text" name=""
              class="form-control unnanu-text-input-style-1" id="" placeholder="" (ngModelChange)="onChange($event)">
            <label [hidden]="!showFirstNameError" class="error">Enter first name</label>
          </div>
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">Last Name<span class="requiredAsterix">*</span></label>
            <input inputRestriction="noNumbers" [ngClass]="{'error': showLastNameError}" (blur)="validateLastName()" maxlength="60"
              (change)="validateLastName()" [(ngModel)]="lastName" type="text" name=""
              class="form-control unnanu-text-input-style-1" id="" placeholder="" (ngModelChange)="onChange($event)">
            <label [hidden]="!showLastNameError" class="error">Enter last name</label>
          </div>
          <div class="unnanu-form-group-style-2 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">Preferred Work Title<span
            class="requiredAsterix">*</span></label>
            <input inputRestriction="noNumbers" [ngClass]="{'error': showPositionError}" maxlength="100" [(ngModel)]="position"
              type="" name="" class="form-control unnanu-text-input-style-1" id="" value="" placeholder=""
              [ngbTypeahead]="search" (selectItem)="positionSelected($event)" [resultFormatter]="formatter" (ngModelChange)="onChange($event)">
            <label [hidden]="!showPositionError" class="error">Enter work title</label>
          </div>
          <div class="unnanu-form-group-style-2 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">Alternate Title (AI matching)</label>
            <input inputRestriction="noNumbers" maxlength="100" [(ngModel)]="optionalPosition1"
              type="" name="" class="form-control unnanu-text-input-style-1" id="" value="" placeholder=""
              [ngbTypeahead]="search1" (selectItem)="opPositionSelected1($event)" [resultFormatter]="formatter" (ngModelChange)="onChange($event)">
          </div>
          <div class="unnanu-form-group-style-2 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">Other Title (AI matching)</label>
            <input inputRestriction="noNumbers" maxlength="100" [(ngModel)]="optionalPosition2"
              type="" name="" class="form-control unnanu-text-input-style-1" id="" value="" placeholder=""
              [ngbTypeahead]="search2" (selectItem)="opPositionSelected2($event)" [resultFormatter]="formatter" (ngModelChange)="onChange($event)">
          </div>
          <div class="unnanu-form-group-style-2">
            <div class="d-flex justify-content-between">
              <label class="unnanu-input-label-style-1">Desired Pay Rate (Per Hour)<span
              class="requiredAsterix">*</span></label>
              <label class="unnanu-input-label-style-2" style="margin:0;">Annually (approx.):
                <span>${{roundSalRange(salarySlider.minValue) | number}}K</span> -
                <span>${{roundSalRange(salarySlider.maxValue) | number}}K</span></label>
              </div>
              <div class="pay-range-slider">
                <div class="row">
                  <div class="slider-drag-element col-9">
                    <ngx-slider [(value)]="salarySlider.minValue" [(highValue)]="salarySlider.maxValue"
                      [options]="salarySlider.options" (userChange)="salarySlider.onChange($event)">
                    </ngx-slider>
                  </div>
                  <div class="slider-value-selected col-3">
                    ${{salarySlider.minValue}}-${{salarySlider.maxValue}}
                  </div>
                  <div class="col-12">
                    <hr>
                    </div>
                  </div>
                </div>
              </div>
              <div class="unnanu-form-group-style-1">
                <label class="unnanu-input-label-style-1">Preferred Work Type<span
                class="requiredAsterix">*</span></label>
                <div class="availability-options d-flex justify-content-between">
                  <div>
                    <span class="unnanu-check-box-style-1">
                      <input [(ngModel)]="fullTime" type="checkbox" id="FullTimeCheck" name="" class="form-check" (ngModelChange)="onChange($event)">
                      <label for="FullTimeCheck"></label>
                    </span>
                    <label class="availability-label">Full-Time</label>
                  </div>
                  <div>
                    <span class="unnanu-check-box-style-1">
                      <input [(ngModel)]="contract" type="checkbox" id="ContractCheck" name="" class="form-check"
                        checked (ngModelChange)="onChange($event)">
                      <label for="ContractCheck"></label>
                    </span>
                    <label class="availability-label">Contract</label>
                  </div>
                  <div>
                    <span class="unnanu-check-box-style-1">
                      <input [(ngModel)]="intern" type="checkbox" id="InternCheck" name="" class="form-check"
                        checked (ngModelChange)="onChange($event)">
                      <label for="InternCheck"></label>
                    </span>
                    <label class="availability-label">Internship</label>
                  </div>
                  <div>
                    <span class="unnanu-check-box-style-1">
                      <input [(ngModel)]="Volunteer" type="checkbox" id="VolunteerCheck" name=""
                        class="form-check" (ngModelChange)="onChange($event)">
                      <label for="VolunteerCheck"></label>
                    </span>
                    <label class="availability-label">Volunteer</label>
                  </div>
                </div>
                <label [hidden]="(fullTime || intern || contract || Volunteer)" class="error">Select at least one work
                type</label>
              </div>
              <div class="unnanu-form-group-style-1">
                <label class="unnanu-input-label-style-1">Work Authorization<span
                class="requiredAsterix">*</span></label>
                <select [(ngModel)]="workAuth" class="form-control" (change)="validateWorkAuth()" (ngModelChange)="onChange($event)">
                  <option value="-1">Select Your Work Authorization Status</option>
                  <option value="1">US Citizen</option>
                  <option value="2">Green Card Holder</option>
                  <option value="3">Employment Authorization</option>
                  <option value="4">Have H1 Visa</option>
                  <option value="5">Need H1 Visa</option>
                  <option value="6">Canadian Citizen</option>
                  <option value="7">TN Permit Holder</option>
                </select>
                <label class="error" [hidden]="!workAuthError">Select Your Work Authorization</label>
              </div>
              <div class="unnanu-form-group-style-1">
                <label class="unnanu-input-label-style-1">Availability<span class="requiredAsterix">*</span></label>
                <select [(ngModel)]="Availability" class="form-control" (change)="validateAvailability()" (ngModelChange)="onChange($event)">
                  <option value="-1">Select Your Availability Status</option>
                  <option value="7">Open</option>
                  <option value="1">Immediate</option>
                  <option value="2">In Two Weeks</option>
                  <option value="3">In a Month</option>
                  <option value="4">In Two Months</option>
                  <option value="5">After Two Months</option>
                  <option value="6">Not Available</option>
                </select>
                <label class="error" [hidden]="!AvailabilityError">Select Your Availability</label>
              </div>
              <div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
                <label class="unnanu-input-label-style-1">Residency Location<span
                class="requiredAsterix">*</span></label>
                <div class="d-flex justify-content-between">
                  <div class="has-sussgestions-dropdown" style="width:314px;">
                    <city-suggestion [basicInfo]="basicInfo" [parentobj]="parentobj"
                    [showCityError]="showCityError" (cityUpdated)="onChange($event)"></city-suggestion>
                    <label [hidden]="!parentobj.showCityError" class="error">Enter your home city or town</label>
                  </div>
                  <div class="willing-to-relocate">
                    <span class="unnanu-check-box-style-1">
                      <input [(ngModel)]="willRelocate" type="checkbox" id="willingToRelocate" name=""
                        class="form-check" (ngModelChange)="onChange($event)">
                      <label for="willingToRelocate"></label>
                    </span>
                    <span>Willing to Relocate</span>
                  </div>
                </div>
              </div>
              <span class="requiredAsterix">*</span>
              <label class="unnanu-input-label-style-1">Required Information </label>
            </div>
            <div class="modal-footer text-right">
              <button type="button" class="cancel-changes-button large" (click)="close()">Cancel</button>
              <button type="button" class="save-changes-button large"
                [disabled]="!valueUpdated || !firstName || !lastName || !position || (!intern && !contract && !fullTime && !Volunteer) || !workAuth || parentobj.showCityError"
              (click)="saveData()">Save Changes</button>
            </div>
          </div>
        </div>
