<header class="app-header">
  <!-- Begin Navigation -->
  <div class="unnanu-navbar-v2 logged-in-user-navigation container-fluid" >
    <nav class="navbar navbar-expand-lg navbar-dark bg-transparent d-flex justify-content-between" style="padding: 0.5rem 1.2rem">
      <a class="navbar-brand" href="#" style="margin:0;">
        <svg width="115" height="29" viewBox="0 0 115 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M114.981 13.3767C114.961 13.4147 114.928 13.4509 114.922 13.4911C114.849 13.9683 114.559 14.285 114.163 14.5217C113.527 14.9012 112.851 15.1866 112.143 15.4023C111.005 15.7487 109.84 15.8044 108.665 15.7478C107.619 15.6974 106.652 15.3895 105.791 14.7872C104.936 14.1892 104.395 13.3665 104.116 12.3655C103.976 11.8591 103.894 11.3432 103.89 10.8181C103.875 9.21973 103.864 7.62133 103.86 6.0229C103.858 5.27981 103.876 4.53663 103.885 3.79351C103.888 3.55736 103.907 3.32581 103.987 3.09837C104.196 2.50789 104.873 2.25838 105.346 2.37771C105.945 2.52889 106.291 2.90434 106.344 3.51632C106.366 3.76534 106.38 4.01586 106.38 4.26573C106.384 6.36582 106.383 8.46594 106.385 10.566C106.386 11.0983 106.498 11.6038 106.766 12.0688C107.154 12.7424 107.746 13.1412 108.495 13.2987C109.566 13.5242 110.604 13.3817 111.606 12.9544C111.775 12.8823 111.94 12.7996 112.104 12.7155C112.336 12.5961 112.435 12.4073 112.435 12.1409C112.43 10.1881 112.434 8.23544 112.435 6.28271C112.436 5.5753 112.432 4.86788 112.438 4.16052C112.44 3.91062 112.444 3.65785 112.482 3.41177C112.551 2.96809 112.79 2.64323 113.212 2.45654C114.053 2.08394 114.92 2.76535 114.919 3.59413C114.918 4.93521 114.922 6.27632 114.923 7.61741C114.924 9.16476 114.924 10.7121 114.929 12.2595C114.929 12.3813 114.963 12.5031 114.981 12.6249V13.3767Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.49473 10.7637C8.46146 5.77266 12.5421 1.7104 17.5792 1.75178C22.465 1.79194 26.5553 5.80675 26.52 10.8405C26.4854 15.7731 22.4069 19.856 17.4348 19.8255C12.4826 19.795 8.43807 15.6917 8.49473 10.7637Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M18.7309 28.0575C18.7309 27.9282 18.7308 27.8198 18.7309 27.7114C18.732 27.1007 18.7406 26.4899 18.7325 25.8793C18.7198 24.929 18.6857 23.9785 18.903 23.0422C18.993 22.6542 19.132 22.2965 19.4603 22.0399C19.6673 21.8782 19.9005 21.77 20.1476 21.6943C20.7248 21.5175 21.3043 21.348 21.8839 21.1793C22.8072 20.9106 23.7005 20.5779 24.5021 20.0294C25.7816 19.1538 26.7323 18.0113 27.3632 16.5937C27.8613 15.4744 28.1865 14.3087 28.3498 13.0982C28.4097 12.6542 28.4309 12.2042 28.4534 11.7561C28.4742 11.3445 28.4811 10.9317 28.4798 10.5195C28.4774 9.71652 28.4555 8.91351 28.4588 8.11056C28.4654 6.5411 28.459 4.97093 28.5066 3.40252C28.5364 2.42191 28.8931 1.54865 29.6198 0.855205C30.0623 0.432949 30.5968 0.218328 31.1959 0.122033C31.568 0.0622364 31.9304 0.0906276 32.2937 0.149156C33.1589 0.288553 33.7563 0.804498 34.1907 1.53416C34.5059 2.06347 34.6613 2.64502 34.6974 3.25626C34.7269 3.75599 34.744 4.25677 34.7531 4.75734C34.7688 5.61173 34.7841 6.46636 34.7808 7.32082C34.777 8.30798 34.7551 9.2951 34.7365 10.2822C34.7274 10.7682 34.7199 11.2548 34.6905 11.7398C34.6455 12.4817 34.5892 13.2231 34.5252 13.9636C34.4287 15.0806 34.2299 16.1808 33.9463 17.2653C33.4342 19.2236 32.5718 21.0088 31.2877 22.5798C30.5562 23.4746 29.714 24.2536 28.7663 24.9176C27.5408 25.7762 26.236 26.481 24.8175 26.9679C23.9723 27.258 23.1079 27.4699 22.2239 27.5964C21.1531 27.7496 20.0807 27.8916 19.0089 28.0372C18.9232 28.0488 18.8361 28.0501 18.7309 28.0575Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.1618 28.0667C15.7935 28.0216 15.4581 27.9831 15.1233 27.9391C14.4958 27.8568 13.8691 27.7682 13.2413 27.6879C11.3001 27.4396 9.45622 26.8901 7.74153 25.9314C6.83224 25.423 5.96501 24.8542 5.16952 24.1799C4.17777 23.3393 3.3472 22.3596 2.65266 21.2618C1.65844 19.6902 1.03773 17.9737 0.68828 16.1566C0.553619 15.4564 0.458854 14.7476 0.370236 14.0396C0.242105 13.0159 0.202209 11.9855 0.183935 10.9543C0.17298 10.3356 0.147196 9.71717 0.140714 9.0985C0.126076 7.70604 0.0986416 6.31317 0.118016 4.92106C0.127726 4.2237 0.116317 3.52133 0.237632 2.82767C0.390375 1.95441 0.761327 1.20567 1.46087 0.64229C1.83806 0.338525 2.27809 0.192766 2.75617 0.122589C3.21082 0.0558565 3.65266 0.089725 4.09028 0.204247C4.88365 0.411859 5.45576 0.90788 5.86019 1.6069C6.2422 2.26717 6.39975 2.98558 6.3996 3.7448C6.39908 6.1918 6.40003 8.63881 6.40453 11.0858C6.40743 12.6512 6.62444 14.1828 7.15055 15.6633C7.52834 16.7265 8.02154 17.7265 8.76681 18.5862C9.66242 19.6192 10.7331 20.4096 12.0306 20.8583C12.7606 21.1108 13.5091 21.3095 14.2484 21.535C14.6003 21.6424 14.9566 21.7393 15.2743 21.9354C15.5607 22.1121 15.7678 22.3532 15.8746 22.6745C16.0124 23.0887 16.1008 23.5149 16.121 23.9502C16.1466 24.5017 16.1597 25.0542 16.1605 25.6063C16.1612 26.0848 16.1309 26.5634 16.127 27.0421C16.1249 27.2993 16.15 27.5566 16.1613 27.814C16.1644 27.8861 16.1618 27.9584 16.1618 28.0667Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M49.6864 8.64572C49.6864 10.1711 49.68 11.6965 49.6898 13.2218C49.6933 13.7579 49.4884 14.1489 49.0309 14.4347C48.0037 15.0763 46.897 15.4874 45.6987 15.6649C44.8994 15.7832 44.0968 15.7893 43.2956 15.74C42.4401 15.6875 41.6265 15.4542 40.8836 15.0163C39.7249 14.3333 39.0341 13.3126 38.7576 12.0054C38.6355 11.4278 38.5816 10.842 38.582 10.251C38.5836 8.1508 38.5797 6.05064 38.587 3.95053C38.5878 3.71029 38.6183 3.46235 38.6834 3.23163C38.8563 2.61844 39.2328 2.39214 39.7454 2.3527C40.3461 2.30649 40.9353 2.68132 41.0629 3.35376C41.0942 3.51899 41.1053 3.69029 41.1056 3.85882C41.1096 6.04004 41.1097 8.22124 41.1115 10.4024C41.1119 10.8631 41.1809 11.3118 41.3515 11.7423C41.6918 12.601 42.3324 13.1089 43.2208 13.2982C44.276 13.523 45.301 13.3834 46.293 12.9746C46.463 12.9045 46.6277 12.8214 46.7943 12.7431C47.0564 12.62 47.164 12.4184 47.1634 12.1243C47.1581 9.43465 47.1604 6.74497 47.1639 4.05531C47.1642 3.84246 47.1763 3.62748 47.2077 3.41719C47.3025 2.78319 47.8503 2.30735 48.4747 2.35344C49.1194 2.40102 49.553 2.78082 49.6409 3.4751C49.6722 3.72278 49.6828 3.97447 49.6834 4.22435C49.6872 5.69813 49.6853 7.17194 49.6853 8.64572H49.6864Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M86.2963 11.0191C86.2963 9.94322 86.3055 8.8672 86.2908 7.79154C86.2862 7.46314 86.2479 7.1278 86.1696 6.80928C85.9642 5.97388 85.4483 5.39194 84.648 5.06682C83.6339 4.6548 82.6464 4.70926 81.6978 5.25716C80.9938 5.66382 80.6181 6.30393 80.4754 7.08826C80.4374 7.29691 80.43 7.51251 80.4244 7.72533C80.4161 8.03462 80.4232 8.3443 80.4218 8.65378C80.4127 10.5473 80.4046 12.4408 80.3915 14.3342C80.3902 14.5243 80.3789 14.7194 80.3351 14.9033C80.2029 15.458 79.713 15.8214 79.1399 15.8351C78.729 15.845 78.392 15.7102 78.122 15.4008C77.9034 15.1504 77.8627 14.8427 77.8532 14.5324C77.8377 14.0243 77.8396 13.5156 77.8366 13.0072C77.8269 11.3495 77.8051 9.69179 77.815 8.03424C77.8197 7.2621 77.8649 6.48791 78.108 5.74221C78.5483 4.39105 79.4314 3.45182 80.7402 2.90902C81.6974 2.51199 82.7024 2.39125 83.7274 2.43426C84.5794 2.47002 85.4069 2.63682 86.1873 2.99943C87.5848 3.64879 88.417 4.74253 88.7364 6.23871C88.8288 6.67175 88.8757 7.11051 88.8815 7.55391C88.8896 8.17254 88.9128 8.79114 88.9112 9.4097C88.9069 11.0371 88.8945 12.6645 88.8798 14.2919C88.878 14.4968 88.8655 14.7067 88.8196 14.9053C88.6672 15.5655 88.1868 15.866 87.5055 15.817C86.9849 15.7796 86.5905 15.5292 86.4053 15.0284C86.333 14.833 86.3049 14.6115 86.3031 14.4015C86.2935 13.2741 86.2986 12.1466 86.2986 11.0191H86.2963Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M90.7637 9.63868C90.7637 8.15025 90.7637 6.66181 90.7637 5.17339C90.7637 5.05549 90.767 4.93753 90.7641 4.8197C90.754 4.40068 90.9325 4.08648 91.2764 3.85124C91.821 3.47869 92.4047 3.18394 93.023 2.95839C93.8238 2.66623 94.6504 2.49576 95.5035 2.4633C95.886 2.44876 96.2689 2.42393 96.6511 2.43125C97.5055 2.44766 98.335 2.59729 99.1177 2.95595C100.221 3.46152 101 4.27529 101.44 5.40941C101.65 5.94904 101.779 6.50569 101.797 7.08612C101.807 7.4245 101.828 7.76282 101.828 8.1012C101.83 10.1128 101.83 12.1244 101.825 14.136C101.825 14.3711 101.816 14.6093 101.778 14.8406C101.666 15.5186 101.17 15.862 100.5 15.8174C100.391 15.8102 100.28 15.7899 100.175 15.7577C99.6782 15.6048 99.375 15.1886 99.3467 14.6244C99.3389 14.47 99.342 14.315 99.3417 14.1603C99.3387 12.1266 99.3407 10.0928 99.3286 8.05917C99.3264 7.68554 99.3047 7.30423 99.2272 6.94027C98.9815 5.7861 98.2371 5.11421 97.1001 4.87314C96.1249 4.66636 95.1817 4.83989 94.2614 5.17516C94.0477 5.25303 93.8423 5.35576 93.6377 5.45658C93.372 5.58761 93.2546 5.7972 93.2806 6.10279C93.2993 6.32128 93.2678 6.54358 93.2676 6.7642C93.2647 9.1958 93.2651 11.6274 93.2598 14.059C93.2593 14.3083 93.2452 14.5601 93.2074 14.8062C93.1158 15.403 92.6413 15.8503 91.9605 15.8206C91.2757 15.7908 90.8069 15.3099 90.7684 14.6345C90.7638 14.5536 90.7656 14.4724 90.7656 14.3913C90.7654 12.8071 90.7655 11.2229 90.7655 9.63868H90.7637Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M51.4517 9.61552C51.4517 8.02367 51.4556 6.4318 51.4492 4.83998C51.4475 4.40155 51.6266 4.06808 51.9823 3.83335C52.5684 3.44651 53.1912 3.13232 53.8601 2.90847C55.0061 2.52491 56.1838 2.37076 57.3861 2.43467C58.0914 2.47215 58.7954 2.54673 59.4644 2.80885C60.5021 3.21534 61.3325 3.86384 61.8727 4.85273C62.2127 5.4753 62.4068 6.14662 62.4666 6.85047C62.5065 7.31939 62.5147 7.792 62.5157 8.263C62.5196 10.2528 62.5168 12.2426 62.5123 14.2324C62.5118 14.4379 62.5 14.646 62.4659 14.8483C62.3565 15.4986 61.8742 15.8669 61.181 15.8169C61.0717 15.809 60.9608 15.7889 60.8562 15.7565C60.3559 15.6015 60.066 15.192 60.0324 14.596C60.0237 14.4417 60.027 14.2866 60.0266 14.1319C60.0223 12.142 60.023 10.1522 60.0104 8.16247C60.0078 7.75155 59.9969 7.333 59.9189 6.93175C59.705 5.83078 58.887 5.07601 57.7616 4.86718C56.7734 4.68379 55.8268 4.8334 54.9037 5.19448C54.7119 5.26948 54.5284 5.36521 54.3403 5.44964C54.0678 5.57188 53.9443 5.77935 53.9466 6.07939C53.952 6.77209 53.9465 7.46486 53.9459 8.15761C53.9441 10.1327 53.9445 12.1078 53.9388 14.0828C53.9381 14.3469 53.9335 14.6159 53.8852 14.8741C53.7752 15.4619 53.2783 15.8604 52.6069 15.8171C51.9283 15.7733 51.4926 15.3157 51.4549 14.5237C51.451 14.4428 51.4529 14.3616 51.4529 14.2805C51.4528 12.7255 51.4528 11.1705 51.4528 9.61552H51.4517Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M64.5707 9.66972C64.5707 8.05561 64.5772 6.44145 64.5664 4.82738C64.5634 4.37494 64.7477 4.05221 65.1155 3.81257C66.1243 3.15543 67.2231 2.7386 68.4126 2.55037C69.1217 2.43812 69.8339 2.40298 70.5491 2.43111C71.5484 2.47038 72.5036 2.67414 73.3751 3.19753C74.3562 3.78669 74.9962 4.63594 75.3411 5.71786C75.5145 6.26189 75.6078 6.82003 75.6088 7.39099C75.6121 9.23357 75.6116 11.0762 75.6116 12.9187C75.6117 13.442 75.6105 13.9653 75.6056 14.4886C75.6046 14.5983 75.5933 14.7094 75.5729 14.8173C75.4489 15.4733 75.0864 15.8084 74.4252 15.8224C73.8203 15.8352 73.3486 15.5611 73.1858 14.9089C73.1294 14.6835 73.1256 14.4411 73.1249 14.2064C73.1192 12.1943 73.1233 10.1822 73.1151 8.17006C73.1135 7.79564 73.1043 7.41531 73.0393 7.04823C72.8271 5.85076 72.0395 5.08862 70.8459 4.86728C69.8254 4.67803 68.8537 4.85202 67.9074 5.23634C67.7506 5.30001 67.5991 5.37679 67.4448 5.44653C67.1796 5.56636 67.0693 5.76836 67.0698 6.06143C67.0731 8.23569 67.0715 10.41 67.0649 12.5842C67.0629 13.2619 67.047 13.9397 67.0254 14.6171C67.0144 14.9592 66.901 15.2715 66.6383 15.5048C66.2467 15.8527 65.7887 15.9046 65.3161 15.725C64.8623 15.5525 64.6235 15.1943 64.5813 14.7108C64.5697 14.579 64.5723 14.4457 64.5723 14.3131C64.5717 12.7653 64.5719 11.2175 64.5719 9.66972H64.5707Z"
            fill="white" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M84.5941 10.1428C84.6419 10.814 84.0337 11.3959 83.3594 11.3892C82.6245 11.3819 82.0997 10.7776 82.0977 10.1465C82.0955 9.47751 82.6845 8.88545 83.3561 8.8877C84.0412 8.89002 84.6333 9.46294 84.5941 10.1428Z"
            fill="white" />
          <path d="M78.0576 20.385V20.9375H76.9701V24H76.2976V20.9375H75.2051V20.385H78.0576Z" fill="white" />
          <path
            d="M85.595 24H85.075C85.0167 24 84.9683 23.9858 84.93 23.9575C84.8933 23.9275 84.8667 23.8908 84.85 23.8475L84.58 23.11H83.0825L82.8125 23.8475C82.7992 23.8858 82.7725 23.9208 82.7325 23.9525C82.6942 23.9842 82.6467 24 82.59 24H82.0675L83.4875 20.385H84.175L85.595 24ZM83.255 22.635H84.4075L83.9675 21.4325C83.9475 21.3792 83.925 21.3167 83.9 21.245C83.8767 21.1717 83.8533 21.0925 83.83 21.0075C83.8067 21.0925 83.7833 21.1717 83.76 21.245C83.7383 21.3183 83.7167 21.3825 83.695 21.4375L83.255 22.635Z"
            fill="white" />
          <path d="M90.9398 23.445H92.3873V24H90.2673V20.385H90.9398V23.445Z" fill="white" />
          <path
            d="M99.4303 20.385V20.92H97.8278V21.9225H99.0903V22.44H97.8278V23.4625H99.4303V24H97.1503V20.385H99.4303Z"
            fill="white" />
          <path
            d="M104.664 20.385C104.694 20.385 104.719 20.3867 104.739 20.39C104.759 20.3917 104.777 20.3967 104.792 20.405C104.808 20.4117 104.824 20.4225 104.839 20.4375C104.854 20.4508 104.871 20.4692 104.889 20.4925L106.787 22.91C106.78 22.8517 106.775 22.795 106.772 22.74C106.77 22.6833 106.769 22.6308 106.769 22.5825V20.385H107.362V24H107.014C106.961 24 106.917 23.9917 106.882 23.975C106.847 23.9583 106.812 23.9283 106.779 23.885L104.889 21.4775C104.894 21.5308 104.897 21.5842 104.899 21.6375C104.902 21.6892 104.904 21.7367 104.904 21.78V24H104.312V20.385H104.664Z"
            fill="white" />
          <path d="M114.938 20.385V20.9375H113.851V24H113.178V20.9375H112.086V20.385H114.938Z" fill="white" />
        </svg>
      </a>

      <div class="signout-inline" (click)="signOut()">
        Sign out
      </div>
    </nav>
  </div>

  @if (showIncompleteProfileBar) {
    <div class="incomplete-profile-topbar" style="position: fixed;bottom: 0;">
      <div class="container text-center d-flex justify-content-center">
        <img class="company-logo" src="{{fileServerUrlHire + selectedJobData.company.company_logo}}">
        <span class="align-self-center application-text text-left">
          <span>You’re applying for the position of {{selectedJobData.vacancy.job_title}} at
            {{selectedJobData.company.company_name}} -
          {{selectedJobData.vacancy.location.city}},{{selectedJobData.vacancy.location.state}} </span>
        </span>
        <span class="align-self-center cancel-wrapper">
          · <a tabindex="1" (click)="cancelApplication()">Cancel</a>
        </span>
      </div>
      <!-- <style>
      .phone-verification footer.footer,
      .add-profile-photo-video-section footer.footer {
      bottom: 48px;
    }
  </style> -->
</div>
}
<!-- End Navigation -->
</header>
