import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { FileSizeExceedAlertComponent } from '../file-size-exceed-alert/file-size-exceed-alert.component';
import { ProfileDataService } from '../../../core/services/profile-data.service';
import { CommonAlertComponent } from '../../../shared/components/common-alert/common-alert.component';
import { ToastrService } from 'ngx-toastr';
import { EllipsisPipe } from '../../pipes/ellipsis.pipe';
import { AttachmentViewDirective } from '../../directives/attachment-view.directive';
import { NgClass, DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'add-resumes-modal',
    templateUrl: './add-resumes-modal.component.html',
    styleUrls: ['./add-resumes-modal.component.scss'],
    standalone: true,
    imports: [NgClass, AttachmentViewDirective, DatePipe, EllipsisPipe]
})
export class AddResumesModalComponent implements OnInit {

	private ssnUrl = environment.urls.ssnUrl_Obj;

	@Input() resumeName: string = '';
	@Output() saveResume: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteResume: EventEmitter<any> = new EventEmitter<any>();
	@Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

	public enableUploadInEdit: boolean = false;
	public uploadAttachment: boolean = true;
	public disableSave: boolean = true;
	public uploading: boolean = false;
	public showUploaded: boolean = false;
	public enableSave: boolean = false;
	public enableDelete: boolean = false;
	public hideSaveButton: boolean = false;
	public editInProgress: boolean = false;

	public fileServerUrl: string = environment.urls.fileServerUrl_Obj;
	public UserName: string = '';
	public fileName: string = '';
	public fileData: any;
	public ResumeData: any[] = [];
	isProcessing: boolean = false;

	constructor(private element: ElementRef,
		public modal: NgbActiveModal,
		private userService: UserService,
		private cookieService: CookieService,
		private modalService: NgbModal,
		private profileDataService: ProfileDataService,
		private toastr: ToastrService) { }

	ngOnInit() {
		this.fileName = this.resumeName;
		this.UserName = this.cookieService.get('username');
		this.ResumeSort(0);
	}

	previewAttachmentView(file: any) {
		console.log(file)

		if (file) {
			if (file.size <= 2000000) {
				if (file.type !== 'image/gif' || file.type !== 'image/jpeg') {
					this.uploading = true;
					this.uploadAttachment = false;
					file.uploadUrl = `${this.ssnUrl}profile/resume/upload`;
					this.userService.uploadAttachment(file).subscribe((response: any) => {
						if (response.Code === 200) {
							this.fileData = response.Data;
							this.fileName = this.cookieService.get('username');
							this.uploading = false;
							this.showUploaded = true;
							this.disableSave = false;
							this.enableSave = true;
							// Check if user update a file after click the delete
							if (this.enableDelete = true) {
								this.enableDelete = false;
							}
						} else {
							this.uploadAttachment = true;
							this.uploading = false;
						}
					})
				}
			} else {
				this.fileSizeExceed();
				this.element.nativeElement.querySelector('input[type="file"]').value = null;
			}
		}
	}

	private fileSizeExceed() {
		let modal = this.modalService.open(FileSizeExceedAlertComponent, { windowClass: 'alert-popup-common', backdrop: 'static', keyboard: false });
		modal.componentInstance.msg = 'Oops! Your File Size cannot Exceed 2MB.';
	}

	getAttachmentViewUrl(url: string): string {
    if (!url || url === null || url === undefined) {
      return '';
    }
    return this.fileServerUrl+url;
  }

	// Clear Attachment in initail upload
	updateResume(resumeId: any, deactivate: boolean,fileName:any) {


		let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
		modal.componentInstance.header = deactivate ? 'Deactivate Resume Request' : 'Activate Resume Request';
		modal.componentInstance.msg = deactivate
			? `You are about to deactivate the "${fileName}" resume. Ensure you upload a new one or activate an existing resume.`
			: `You are about to activate the "${fileName}" resume.`;
		modal.componentInstance.confirmButton = 'OK';
		modal.componentInstance.cancelButton = 'Cancel';
		modal.componentInstance.showCancelButton = true;

		modal.result.then(
			(result) => {
				if (result === 'OK') {

					if (deactivate) {
						this.enableSave = true;
						this.dataChange.emit(null); // Emit a change event for deactivation
					} else {
						this.enableSave = true;
						let obj = this.ResumeData.find(resume => resume.resume_id === resumeId);
						this.dataChange.emit(obj);
						this.profileDataService.setEditResumeId(obj.resume_id);
						this.profileDataService.setResumeUrl(obj.attachment_url);
					}
					this.ResumeSort(resumeId);
				}
			},
			() => {
				// User dismissed the modal, no action needed
				console.log('Action canceled by the user.');
			}
		);
		// this.modal.dismiss();
	}
	// Resume Data Sort
	ResumeSort(resumeId: any){
		this.userService.updateResume(resumeId).subscribe((response: any) => {
			this.ResumeData = response.Data;
			this.ResumeData.sort((a, b) => {
				// Sort by status first (active first)
				if (a.status !== b.status) {
					return a.status ? -1 : 1; // Active (true) before Inactive (false)
				}
				// Then sort alphabetically by file_name
				return a.file_name.localeCompare(b.file_name);
			});
		});
	}

	// Save attachment
	save() {
		// start uploading
		if(this.fileData){
			let obj = this.fileData
			let objToUpload = {
				resume: {
					attachment_type: this.fileData.attachment_type,
					attachment_url: this.fileData.attachment_url,
					file_name: this.fileData.file_name,
					co_guid: ''
				}
			};
			this.userService.addComponent(objToUpload).subscribe((response: any) => {
				if (response.Code === 200) {
					this.saveResume.emit({ data: this.fileData });
					this.profileDataService.setEditResumeId(response.Data.Resume.Id);
					this.profileDataService.setResumeUrl(this.fileData.attachment_url);
				} else {
					console.log("error");
				}
			})
			this.modal.dismiss();
		}
		else{
			this.saveResume.emit({ data: null });
			this.modal.dismiss();
			this.toastr.success(`Successfully Activated`);
		}

	}
	onDownload(event: Event, attachURL: string) {
		// event.preventDefault(); // Prevent default anchor click behavior
		if (this.isProcessing) {
		  return; // Prevent multiple simultaneous calls
		}

		this.isProcessing = true;

		// this.userService.processAttachmentUrl(attachURL).subscribe({
		//   next: (modifiedUrl) => {
		// 	window.location.href = modifiedUrl; // Redirect to the modified URL
		//   },
		//   error: (err) => {
		// 	console.error('Error processing the URL:', err);
		// 	console.log('attachURL: ', attachURL);
		// 	const trans=this.fileServerUrl+attachURL.replace('resumes', 'watermark').replace(/\.docx?|\.txt|\.rtf|\.odt|\.html|\.pdf$/i, '.pdf');

		// 	console.log('trans: ', trans);

		// 	window.location.href =trans
		//   },
		//   complete: () => {
		// 	this.isProcessing = false; // Re-enable the link
		//   },
		// });
		this.userService.convertFiletopdf(attachURL).subscribe(
					(res: HttpResponse<string>) => {
					  console.log('Full API Response:', res); // Logs the full response, including headers and status
					  if (res.status === 200) {
						console.log('Response body:', res.body); // Logs the actual plain text response
						const transformedUrl = this.fileServerUrl+attachURL.replace('/resumes/', '/watermark/').replace(/\.docx?|\.txt|\.rtf|\.odt|\.html|\.pdf$/i, '.pdf');
						console.log('Fallback transformedUrl:', transformedUrl);
						window.location.href= transformedUrl;
						this.isProcessing = false;
					  }
					  else{
						console.log("Response is not 200");

					  }

					},
					(error) => {
					  console.error('Error during API call:', error);
					  window.location.href=this.fileServerUrl+attachURL
					  this.isProcessing = false;
					}
				  );

	  }
	// Update attachment
	update() {
		// start uploading
		// let obj = this.fileData
		// let objToUpload = {
		// 	UpdateRecords: [
		// 		{
		// 			Type: 'RESUME',
		// 			Data: {
		// 				Id: this.profileDataService.getEditResumeId(),
		// 				attachment_type: obj.attachment_type,
		// 				attachment_url: obj.attachment_url,
		// 				file_name: obj.file_name,
		// 				co_guid: ''
		// 			}
		// 		}
		// 	]
		// };

		// this.userService.profileUpdate(objToUpload).subscribe((response: any) => {
		// 	if (response.Code === 200) {
		// 		this.saveResume.emit({ data: this.fileData });
		// 	}
		// })

		// this.modal.dismiss();
		this.save();
	}

	// Delete Attachment
	delete() {
		// console.log(this.profileDataService.getEditResumeId())
		let objToDelete = {
			DeleteIds: [{
				Type: 'RESUME',
				Id: this.profileDataService.getEditResumeId()
			}]
		};

		this.userService.profileUpdate(objToDelete).subscribe((response: any) => {
			if (response.Code === 200) {
				this.deleteResume.emit({ data: null })
			}
		})
		this.modal.dismiss();
	}

	onFileChange(event: any) {
		if (event.target.files.length > 0) {
			this.previewAttachmentView(event.target.files[0]);
		}
	}

	closeThisDialog() {
		this.modal.dismiss();
	}

}
