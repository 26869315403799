import { Directive, ElementRef, HostListener, Input, OnInit, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileViewModalComponent } from '../components/file-view-modal/file-view-modal.component';

@Directive({
	selector: '[attachmentView]'
})
export class AttachmentViewDirective implements OnInit, OnChanges {

	@Input('value') value: any;

	public fileUrl: any;

	constructor(public renderer: Renderer2,
		public elmRef: ElementRef,
		private modalService: NgbModal) {

	}

	ngOnInit() {
		this.updateFileUrl();
	}

	ngOnChanges(changes:SimpleChanges) {
		if (changes["value"]) {
			this.updateFileUrl();
		}
	}

	private updateFileUrl() {
		this.fileUrl = this.value || '';
	}

	openModal(fileUrl: any): void {
		const modal = this.modalService.open(FileViewModalComponent);
		modal.componentInstance.fileUrl = fileUrl;
	}

	@HostListener('click', ['$event']) onClick($event: any) {
		this.openModal(this.fileUrl);
	}
}
