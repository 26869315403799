import { HttpClient } from '@angular/common/http';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { EventService } from '../../../core/services/event.service';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonAlertComponent } from '../common-alert/common-alert.component';
import { SocketIOService } from 'src/app/core/services/socket-io.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailpromptModalComponent } from '../emailprompt-modal/emailprompt-modal.component';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss'],
    standalone: true
})
export class SocialLoginComponent implements OnInit, OnDestroy {

	@Input() showOr: boolean = true;

	public isAeSigningUp: boolean = false;
	public isGeSigningUp: boolean = false;
	public isFbSigningUp: boolean = false;
	public isLiSigningup:boolean=false
	private user: any = {};

	private googleSignInData: any = {
		gid: '',
		gname: '',
		gemail: '',
		gfname: '',
		glname: ''
	}

	private appleSigninData: any = {
		aid: '',
		email: '',
		firstName: '',
		lastName: ''
	}

	constructor(private http: HttpClient,
		private zone: NgZone,
		private userService: UserService,
		private socketIOService: SocketIOService,
		private eventService: EventService,private route:ActivatedRoute,private router:Router,private toastr: ToastrService,
		private modalService: NgbModal) { }

	ngOnInit() {
		(window as any).fbAsyncInit = function () {
			FB.init({
				appId: environment.urls.facebookAppID,
				xfbml: true,
				version: 'v2.8'
			});
		};

		// load facebook sdk script
		(function (d, s, id) {
			let js: any, fjs: any = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));

		// Used for social login is user exists
		this.eventService.subscribe('user-signed-in', (payload: any) => {

			this.userSignIn(payload);
		});
		const code = this.route.snapshot.queryParamMap.get('code');

		const state = this.route.snapshot.queryParamMap.get('state');
		const savedState = sessionStorage.getItem('oauthState');

		const error = this.route.snapshot.queryParamMap.get('error');
		const errorDescription = this.route.snapshot.queryParamMap.get('error_description');

		if (error === 'user_cancelled_login') {
			this.toastr.error(`${errorDescription}`);
			this.router.navigate(['/login']);

			return;
		}

		if (!code || !state || state !== savedState) {

		  return;
		}
		  // Exchange authorization code for access token
		  this.userService.getAccessToken(code).subscribe(
			(tokenResponse: any) => {
			  const accessToken = tokenResponse;
			  console.log('accessToken: ', accessToken);
			  const data=tokenResponse.Data
			  console.log('accessToken: ', data);
			  const userData = {
				  linkedinId: data.sub,
				  firstName: data.given_name,
				  lastName: data.family_name,
				  email: data.email,
				  password:'',
				  image:data.picture
				};
				console.log('userData: ', userData);
				  this.nliAuthenticate(userData);
			  // Fetch LinkedIn user profile and email
			//   this.linkedinservice.getLinkedInProfile(accessToken).subscribe(
			// 	(profileResponse: any) => {
			// 	  this.linkedinservice.getLinkedInEmail(accessToken).subscribe(
			// 		(emailResponse: any) => {
			// 		  const userData = {
			// 			linkedinId: profileResponse.id,
			// 			firstName: profileResponse.localizedFirstName,
			// 			lastName: profileResponse.localizedLastName,
			// 			email: emailResponse.elements[0]['handle~'].emailAddress,
			// 			password:''
			// 		  };

			// 		  // Use the `nliAuthenticate` method here
			// 		  this.nliAuthenticate(userData);
			// 		}
			// 	  );
			// 	}
			//   );
			},
			(error:any) => {
			  console.error('Error getting access token', error);
			}
		  );
	}

	ngOnDestroy() {

	}

	// Only used for social login
	private userSignIn(args: any) {
		this.userService.setCookie('token', args.response.Data.Token);
		this.userService.setCookie('stage', args.response.Data.Stage);
		this.userService.setCookie('user_id', args.response.Data.User_id);
		this.userService.setCookie('stageToRedirect', args.response.Data.Stage_To_Redirect);
		// connect messaging service
		if (this.socketIOService.getConnectionStatus() === false ||
			this.socketIOService.getConnectionStatus() === undefined) {
			this.socketIOService.connect();
		}

		if (Number(args.response.Data.Stage) === 5) {
			this.userService.profileInfo().subscribe((response: any) => {
				if (response.status === 200 && response.Code === 200) {
					console.log(response);
					this.eventService.broadcast('ShowResumePopup', response);
				}
			});
		}

		this.userService.redirectUser(Number(args.response.Data.Stage), true);
	}

	private nliAuthenticate(userData: any): void {
		console.log('userData.email: ', userData.email);

		if (userData.email) {
			// If email is available, proceed with LinkedIn authentication
			this.proceedWithLinkedInSignup(userData);
		} else {
			console.log('Checking LinkedIn user with ID:', userData.linkedinId);

			this.userService.checklnuser(userData.linkedinId).subscribe((res: any) => {
				console.log('LinkedIn check response:', res);

				if (!res.Data) {
					console.log("No email found, prompting user for email.");

					let modal = this.modalService.open(EmailpromptModalComponent, { backdrop: 'static', keyboard: false });
					modal.componentInstance.header = 'Enter Your Email';
					modal.componentInstance.msg = 'We could not retrieve your email from LinkedIn. Please enter your email to proceed.';

					// Handle email input
					modal.result.then((userEmail) => {
						if (userEmail) {
							userData.email = userEmail;
							this.proceedWithLinkedInSignup(userData);
						} else {
							console.log("User canceled email entry. No further action needed."); // ✅ No error popup
						}
					}).catch(() => {
						console.log("User dismissed email entry modal. No action needed."); // ✅ No error popup
					});

				} else {
					// Email was retrieved from LinkedIn, proceed normally
					console.log("REached here");

					console.log('res: ', res);
					console.log('res.Data.Data: ', res.Data);
					userData.email = res.Data;
					this.proceedWithLinkedInSignup(userData);
				}
			});
		}
	}

	// Function to proceed with LinkedIn signup after getting an email
	private proceedWithLinkedInSignup(userData: any): void {
		console.log('userData: ', userData);
		this.userService.linkedinAuth({
			email: userData.email,
			token: userData.linkedinId,
			password: '',
		}).subscribe((response: any) => {
			console.log('LinkedIn authentication response:', response);

			if (response.status === 200 && response.Code === 401) {
				// User is logging in for the first time; sign them up
				let user = {
					email: userData.email,
					firstName: userData.firstName,
					lastName: userData.lastName,
					password: '',
					token: userData.linkedinId || '',
					profileImg:userData.image
				};
				this.userService.signUp(user, 'LINKEDIN').subscribe((signUpResponse: any) => {
					console.log('LinkedIn Signup response:', signUpResponse);

					if (signUpResponse.status === 200) {
						// Immediately sign in the user
						this.userService.linkedinAuth({
							email: userData.email,
							token: userData.linkedinId,
							password: '',
						}).subscribe((authResponse: any) => {
							console.log('Auth response after signup:', authResponse);
							if (authResponse.status === 200 && authResponse.Code === 200) {
								this.userService.setCookie('userEmail', userData.email);
								this.userService.setCookie('user_id', authResponse.Data.User_id);
								this.eventService.broadcast('user-signed-in', { response: authResponse });
							}
						});
					}
				});

			} else if (response.status === 200 && response.Code === 200) {
				// User already exists, proceed with login
				this.userService.setCookie('userEmail', userData.email);
				this.userService.setCookie('user_id', response.Data.User_id);
				this.eventService.broadcast('user-signed-in', { response: response });
			} else {
				console.error('Unexpected response during LinkedIn authentication', response);
			}
		}, (error) => {
			console.error('Error during LinkedIn authentication', error);
		});
	}

	applePressed() {
		this.isAeSigningUp = true;
		this.isGeSigningUp = false;
		this.isFbSigningUp = false;
		this.isLiSigningup=false
		AppleID.auth.init({
			clientId: 'com.unnanu.app.service',
			redirectURI: `${environment.urls.app_url_obj}/login`,
			usePopup: true,
			scope: 'name email',
			state: 'UnnanuApp',
		})
		let data = AppleID.auth.signIn();
		//Listen for authorization success
		document.addEventListener('AppleIDSignInOnSuccess', (data: any) => {
			console.log('AppleIDSignInOnSuccess - Success');
			let decoded: any = jwt_decode(data.detail.authorization.id_token);
			this.appleSigninData.aid = decoded.sub;
			this.appleSigninData.email = decoded.email;
			if (data.detail.user != undefined) {
				this.appleSigninData.firstName = data.detail.user.name.firstName;
				this.appleSigninData.lastName = data.detail.user.name.lastName;
			} else {
				let nameSplitted = decoded.email.split('@');
				this.appleSigninData.firstName = nameSplitted[0];
				this.appleSigninData.lastName = 'AppleWS';
			}
			this.aeAuthenticate(this.appleSigninData);
		});
		//Listen for authorization failures
		document.addEventListener('AppleIDSignInOnFailure', (error) => {
			console.log('AppleIDSignInOnFailure', error);
			this.isAeSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Apple login again';
			modal.componentInstance.msg = 'We are unable to authenticate your account at Apple. Try again now or try after updating Apple account settings.';
		});
	}

	private aeAuthenticate(data: any) {
		if (data.email) {
			this.userService.appleAuth(
				{
					email: data.email,
					token: data.aid,
					password: '',
				}).subscribe((response: any) => {
					if (response.status === 200 && response.Code === 401) {
						// user has not signed up before so sign him in
						let user = {
							email: data.email,
							firstName: data.firstName,
							lastName: data.lastName,
							password: '',
							token: data.aid || ''
						};
						this.isAeSigningUp = true;
						this.user = user;
						this.userService.signUp(this.user, 'APPLE').subscribe();
					} else if (response.status === 200 && response.Code === 200) {
						this.userService.setCookie('userEmail', data.email);
						this.userService.setCookie('user_id', response.Data.User_id);
						this.eventService.broadcast('user-signed-in', { response: response });
					} else {
						this.isAeSigningUp = false;
						let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Apple login again';
			modal.componentInstance.msg = 'We are unable to authenticate your account at Apple. Try again now or try after updating Apple account settings.';
					}
				}
				);
		} else {
			this.isAeSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Apple login again';
			modal.componentInstance.msg = 'We are unable to authenticate your account at Apple. Try again now or try after updating Apple account settings.';
		}
	}

	googlePressed() {
		this.isAeSigningUp = false;
		this.isGeSigningUp = true;
		this.isFbSigningUp = false;
		this.isLiSigningup=false
		gapi.auth2.authorize({
			client_id: '179779178843-cik41cd628og1meleo75rlamd6kr0cm4.apps.googleusercontent.com',
			scope: 'email profile openid',
			response_type: 'id_token permission',

		}, (response) => {
			;
			if (response.error) {
				console.log('Sorry, Google SignIn Error... try again.');
				this.isGeSigningUp = false;
				let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Google login again';
			modal.componentInstance.msg = 'Sorry, We are unable to authenticate with Google. Try after updating Google account settings.';

				return;
			}
			this.http.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${response.access_token}`)
				.subscribe((response: any) => {
					this.googleSignInData = {
						gid: response.id,
						gname: response.name,
						gemail: response.email,
						gfname: response.given_name,
						glname: response.family_name,
						gimage:response.picture
					}
					console.log('googleSignInData: ', this.googleSignInData);
					this.zone.run(() => {
						this.geAuthenticate(this.googleSignInData);
					});
				},
					(error) => {
						console.log('*** Sorry, Google SignIn Error... try again ****');
						this.isGeSigningUp = false;
						let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Google login again';
			modal.componentInstance.msg = 'Sorry, We are unable to authenticate with Google. Try after updating Google account settings.';
						console.log(error);
					});
		});
	}

	private geAuthenticate(data: any) {
		console.log('data: ', data);
		if (data.gemail) {
			this.userService.googleAuth({
				email: data.gemail,
				token: data.gid,
				password: '',
			}).subscribe(async (response: any) => {
				if (response.status === 200 && response.Code === 401) {
					let user = {
						email: data.gemail,
						firstName: data.gfname,
						lastName: data.glname,
						password: '',
						token: data.gid || '',
						profileImg:data.gimage

					};
					console.log('user: ', user);
					console.log(data.gimage);


					this.isGeSigningUp = true;
					this.user = user;
					this.userService.signUp(this.user, 'GOOGLE').subscribe();
				} else if (response.status === 200 && response.Code === 200) {
					// if (data.gimage) {
					// 	try {
					// 		const file = await this.urlToFile(data.gimage, "profile-image.jpg");
					// 		console.log("Converted File:", file);
					// 	} catch (error) {
					// 		console.error("Error converting image to file:", error);
					// 	}
					// }
					this.userService.setCookie('userEmail', data.gemail);
					this.userService.setCookie('user_id', response.Data.User_id);
					this.eventService.broadcast('user-signed-in', { response: response });
				} else {
					this.isGeSigningUp = false;
					let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Google login again';
			modal.componentInstance.msg = 'Sorry, We are unable to authenticate with Google. Try after updating Google account settings.';

				}
			});
		} else {
			this.isGeSigningUp = false;
			let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Sorry, try Google login again';
			modal.componentInstance.msg = 'Sorry, We are unable to authenticate with Google. Try after updating Google account settings.';
		}
	}
	private async urlToFile(url: string, filename: string): Promise<File> {
		const response = await fetch(url, { mode: 'no-cors' });
		const blob = await response.blob();
		const mimeType = blob.type || "image/jpeg";
		const file = new File([blob], filename, { type: mimeType });
		return file;
	}
	LinkedInPressed(){
		this.isAeSigningUp = false;
		this.isGeSigningUp = false;
		this.isFbSigningUp = false;
		this.isLiSigningup=true
		const authUrl = this.userService.getAuthorizationUrl();
		console.log(authUrl);

		window.location.href = authUrl; // Redirect to LinkedIn login page

	}

	facebookPressed() {
		this.isAeSigningUp = false;
		this.isGeSigningUp = false;
		this.isFbSigningUp = true;
		this.isLiSigningup=false
		FB.getLoginStatus((response) => {
			console.log('response: ', response);


			this.zone.run(() => {
				this.statusChangeCallback(response);
			});
		});
	}

	private statusChangeCallback(response: any) {
		console.log('response: ', response);
		// The response object is returned with a status field that lets the
		// app know the current login status of the person.
		// Full docs on the response object can be found in the documentation
		// for FB.getLoginStatus().

		let fbToken: any = null;
		if (response.authResponse) {
			fbToken = response.authResponse.accessToken;
		}
		if (response.status === 'connected') {
			// Logged into your app and Facebook.
			console.log('fbToken: ', fbToken);
			let url = fbToken ? `/me?fields=email,name&access_token=${fbToken}` : '/me?fields=email,name';

			console.log('url: ', url);
			FB.api(url, (res: any) => {
				console.log('res: ', res);
				this.zone.run(() => {
					this.fbAuthenticate(res.name, res.email, fbToken,res.id);
				});
			});
		} else if (response.status === 'not_authorized') {
			// The person is logged into Facebook, but not your app.
			this.fbAPI();
		} else if (response.status === 'unknown') {
			// The person is logged into Facebook, but not your app.
			this.fbAPI();
		} else {
			// The person is not logged into Facebook, so we're not sure if
			// they are logged into this app or not.
			this.fbAPI();
		}
	}

	private fbAPI() {
		let fbToken: any = null;
		FB.login((response) => {
			console.log('response: ', response);
			if (response.authResponse) {
				fbToken = response.authResponse.accessToken;
			}
			let url = '/me?fields=email,name';
			FB.api(url, (res: any) => {
				FB.api(url, (res: any) => {
					this.zone.run(() => {
						console.log('res: ', res);
						this.fbAuthenticate(res.name, res.email, fbToken,res.id);
					});
				});
			});
		},
			{ scope: 'email' }
		); /* perms changed to scope */
	}

	private fbAuthenticate(name: any, email: any, fbToken: any,id:any) {
		if (!email) {
			this.isFbSigningUp = false;

			this.userService.checkfbuser(id).subscribe((res:any)=>{
				console.log(res);
				console.log('res.Result: ', res.Data);


				if (!res.Data) {
					console.log("No email");

					let modal = this.modalService.open(EmailpromptModalComponent, { backdrop: 'static', keyboard: false });
					modal.componentInstance.header = 'Enter Your Email';
					modal.componentInstance.msg = 'We could not retrieve your email from Facebook. Please enter your email to proceed.';

					// Handle email input
					modal.result.then((userEmail) => {
						if (userEmail) {
							this.proceedWithSignup(name, userEmail, fbToken);
						} else {
							this.showEmailNotFoundError();
						}
					}).catch(() => {
						this.showEmailNotFoundError();
					});
				}
				else{
					this.proceedWithSignup(name, res.Data, fbToken);
				}


			},(error)=>{
				console.log(error);
				let modal = this.modalService.open(EmailpromptModalComponent, { backdrop: 'static', keyboard: false });
			modal.componentInstance.header = 'Enter Your Email';
			modal.componentInstance.msg = 'We could not retrieve your email from Facebook. Please enter your email to proceed.';

			// Handle email input
			modal.result.then((userEmail) => {
				if (userEmail) {
					this.proceedWithSignup(name, userEmail, fbToken);
				} else {
					this.showEmailNotFoundError();
				}
			}).catch(() => {
				this.showEmailNotFoundError();
			});
			})
			// Open a modal to ask for email
			// let modal = this.modalService.open(EmailpromptModalComponent, { backdrop: 'static', keyboard: false });
			// modal.componentInstance.header = 'Enter Your Email';
			// modal.componentInstance.msg = 'We could not retrieve your email from Facebook. Please enter your email to proceed.';

			// // Handle email input
			// modal.result.then((userEmail) => {
			// 	if (userEmail) {
			// 		this.proceedWithSignup(name, userEmail, fbToken);
			// 	} else {
			// 		this.showEmailNotFoundError();
			// 	}
			// }).catch(() => {
			// 	this.showEmailNotFoundError();
			// });

		} else if (email && name) {
			this.proceedWithSignup(name, email, fbToken);
		} else {
			this.showEmailNotFoundError();
		}
	}
	private proceedWithSignup(name: any, email: any, fbToken: any) {
		this.userService.facebookAuth({
			email: email,
			token: fbToken,
			password: '',
		}).subscribe((response: any) => {
			if (response.status === 200 && response.Code === 401) {
				let nameSplitted = name.split(' ');
				let user: any = {
					email: email,
					firstName: nameSplitted[0],
					lastName: nameSplitted[nameSplitted.length - 1],
					password: '',
					token: fbToken || '',
					profileImage:null
				};
				this.isFbSigningUp = true;
				this.user = user;
				this.userService.signUp(this.user, 'FACEBOOK').subscribe();
			} else if (response.status === 200 && response.Code === 200) {
				this.userService.setCookie('userEmail', email);
				this.userService.setCookie('user_id', response.Data.User_id);
				this.eventService.broadcast('user-signed-in', { response: response });
			} else {
				this.isFbSigningUp = false;
			}
		});
	}

	// Function to show error message
	private showEmailNotFoundError() {
		this.isFbSigningUp = false;
		let modal = this.modalService.open(CommonAlertComponent, { backdrop: 'static', keyboard: false });
		modal.componentInstance.header = 'Apologies, Email Required!';
		modal.componentInstance.msg = 'We were unable to retrieve your email from Facebook. Please enter a valid email to continue.';
	}

}
