<div class="modal-header">
  <h1 class="modal-title">Onboarding Documents - {{offer.hire_company_name}}</h1>
  <button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="scroll-content">
    @for (onboard of offer.onboarding_attachments; track onboard) {
      <div class="item-wrapper">
        <h3 class="float-left">{{onboard.attachment_title}}</h3>
        @if (onboard.attachment_status === 1) {
          <div class="status waiting">Awaiting Response</div>
        }
        @if (onboard.attachment_status === 2) {
          <div class="status sent">Response Sent</div>
        }
        @if (onboard.attachment_status === 3) {
          <div class="status received">Received</div>
        }
        <div class="msg-wrapper">
          <!--  <p>{{onboard.send_at | date:'EEE, MMM d, y @ h:mm a'}} Madhu -->
          <p>{{onboard.description}}</p>
        </div>
        <div class="button-wrapper">
          <a class="icon-download-alt pull-left icon-2x" style="padding: 7px; " target="_self"
          href="{{attachmentUrl + onboard.attachment_url}}"></a>
          <button style="cursor: pointer;" attachmentView [value]="attachmentUrl + onboard.attachment_url"
          class="btn view-document float-left">View Document</button>
          <button class="btn attach-send float-left" [hidden]="!onboard.is_require_response"
            [disabled]="onboard.disableStage || offer.offer_status == 3 || offer.offer_status == 4"><input
            type="file" accept=".pdf,.docx,.doc"
          (change)="onPreviewAttachmentView($event, onboard.attachment_id)" />Attach & Send</button>
          <div class="info-wrapper ms-auto">
            @if (onboard.attachmentSent) {
              <p class="sent"><span></span> Attachment Sent!</p>
            }
            @if (onboard.viewSent && onboard.is_require_response) {
              <p class="sent attachments"
                (click)="onboard.showAttachments = true; onboard.hideAttachments = true; onboard.viewSent = false">
                View sent attachments <span></span></p>
              }
              @if (onboard.hideAttachments && onboard.response_attachments.length !== 0) {
                <p class="sent attachments up"
                  (click)="onboard.hideAttachments = false; onboard.viewSent = true; onboard.showAttachments = false">
                  Hide sent attachments <span></span></p>
                }
                @if (onboard.attachmentPreview) {
                  <p class="sent uploading"><span></span> Uploading</p>
                }
              </div>
            </div>
            @if (onboard.due_date) {
              <div class="due-date">Due on: {{onboard.due_date | date: 'EEE, MMM d, y'}}</div>
            }
            @if (onboard.showAttachments) {
              <div class="attachment-wrapper">
                @for (ResponseAttachments of onboard.response_attachments; track ResponseAttachments) {
                  <div class="attachment-item">
                    <p class="item">
                      <span
                      [ngClass]="{'pdf':ResponseAttachments.attachment_type == 1, 'image':ResponseAttachments.attachment_type == 2, 'video ':ResponseAttachments.attachment_type == 3, 'audio ':ResponseAttachments.attachment_type == 4, 'other ':ResponseAttachments.attachment_type == 5, 'doc':ResponseAttachments.attachment_type == 6 }"></span>
                      <a style="cursor: pointer;" attachmentView
                      [value]="attachmentUrl + ResponseAttachments.attachment_url">{{ResponseAttachments.file_name}}</a>
                      <a class="icon-download-alt" style="padding: 3px 0px 0px 10px; " target="_self"
                      href="{{attachmentUrl + ResponseAttachments.attachment_url}}"></a>
                    </p>
                    @if (ResponseAttachments.response_status === 1) {
                      <div class="status waiting">In Review</div>
                    }
                    @if (ResponseAttachments.response_status === 2) {
                      <div class="status sent">Accepted</div>
                    }
                    @if (ResponseAttachments.response_status === 3) {
                      <div class="status decline">Not Accepted</div>
                    }
                    @if (ResponseAttachments.response_status === 3) {
                      <div class="status cursor-pointer" (click)="openComment(ResponseAttachments.comment)">
                        <img class="" width="16" src="../../../assets/images/information.png">
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
