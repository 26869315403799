import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../core/services/event.service';
import { CameraComponent } from '../camera/camera.component';

@Component({
    selector: 'photo-capture-modal',
    templateUrl: './photo-capture-modal.component.html',
    styleUrls: ['./photo-capture-modal.component.scss'],
    standalone: true,
    imports: [CameraComponent]
})
export class PhotoCaptureModalComponent implements OnInit {

	public canvas: any;
	public videoElement: any;
	public showCaptureButton: boolean = true;

	constructor(private eventService: EventService,
		private modal: NgbActiveModal) {

	}

	ngOnInit() {

	}

	capturePhoto() {
		this.showCaptureButton = false;
		this.videoElement = document.getElementById('webcam-live');
		this.canvas = document.getElementById('imagePreview');
		let width = this.videoElement.videoWidth;
		let height = this.videoElement.videoHeight;
		this.videoElement.setAttribute('width', width);
		this.videoElement.setAttribute('height', height);
		this.canvas.setAttribute('width', width);
		this.canvas.setAttribute('height', height);

		this.videoElement.pause();
		this.canvas.getContext('2d').drawImage(this.videoElement, 0, 0, width, height);
	}

	savePhoto() {
		if (this.canvas !== undefined) {
			this.eventService.broadcast('photo-selected', {
				data: this.canvas.toDataURL('image/jpeg'),
			});
			this.close();
		}
	}

	retakePhoto() {
		this.showCaptureButton = true;
		this.videoElement.play();
	}

	close() {
		this.eventService.broadcast('dismiss-camera', {});
		this.modal.dismiss();
	}

}
