<div class="modal-header">
  <h4 class="modal-title">Confirm "Reschedule"</h4>
</div>
<div class="modal-body">
  <p class="cancel-text">Are you sure you want to "Reschedule" the interview request from {{companyName}}? If yes,
  provide detailed information with your different available times.</p>
  <textarea class="form-control" id="reschedule-text-area" rows="5" [ngClass]="{'error': remaining() < 0}"
  [(ngModel)]="message" maxlength="{{ maxLength }}"></textarea>
  @if (remaining() < 0) {
    <span>{{ remaining() }}</span>
  }
</div>
<div class="modal-footer text-right">
  <button type="button" class="btn btn-cancel" (click)="closeThisDialog()">CANCEL</button>
  <button type="submit" class="btn btn-send" [disabled]="!(message.length > 0)"
  (click)="rescheduleInterview()">SEND</button>
</div>
