// import { Component, Input, OnInit } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// @Component({
// 	selector: 'file-view-modal',
// 	templateUrl: './file-view-modal.component.html',
// 	styleUrls: ['./file-view-modal.component.scss']
// })
// export class FileViewModalComponent implements OnInit {

// 	@Input() fileUrl: any;

// 	public url: any;
// 	public isLoading: boolean = true;

// 	constructor(public modal: NgbActiveModal,
// 		private sanitizer: DomSanitizer) { }

// 	ngOnInit() {

// 		this.isLoading = true;
//     if (this.fileUrl.split('.').pop() === 'html' || this.fileUrl.split('.').pop() === 'odt') {
//       this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
//     } else {
//       this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
//         `https://docs.google.com/viewer?url=${this.fileUrl}&embedded=true`
//       );
//     }

//     setTimeout(() => this.isLoading = false, 2500);
//   }

// }

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileViewService } from '../../services/file-view.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpResponse } from '@angular/common/http';

@Component({
    selector: 'file-view-modal',
    templateUrl: './file-view-modal.component.html',
    styleUrls: ['./file-view-modal.component.scss'],
    standalone: true,
	imports:[PdfViewerModule]
})
export class FileViewModalComponent implements OnInit {
	@Input() fileUrl: any;

	public url: any;
	public isLoading: boolean = true;
	public nopdf:boolean=false

	@ViewChild('iframeRef', { static: false }) iframeRef:
		| ElementRef
		| undefined;

	private checkIFrameSubscription: Subscription | undefined = undefined;
	curl: any;

	constructor(
		public modal: NgbActiveModal,
		private sanitizer: DomSanitizer,
		private fileviewService:FileViewService
	) {}


	ngOnInit() {
		this.isLoading = true;
		// if (this.fileUrl.split('.').pop() === 'html' ) {
		// 	this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
		// 	this.isLoading = false;
		// } else {
		// 	this.url=this.transformUrl(this.fileUrl)
		// }
		this.url=this.transformUrl(this.fileUrl)
	}

	transformUrl(url: string) {



		this.fileviewService.convertFiletopdf(this.fileUrl).subscribe(
			(res: HttpResponse<string>) => {
			  console.log('Full API Response:', res); // Logs the full response, including headers and status
			  if (res.status === 200) {
				console.log('Response body:', res.body); // Logs the actual plain text response
				const transformedUrl = url.replace('/resumes/', '/watermark/').replace(/\.docx?|\.txt|\.rtf|\.odt|\.html|\.pdf$/i, '.pdf');
				console.log('Fallback transformedUrl:', transformedUrl);
				this.url = transformedUrl;
				this.isLoading = false;
			  }
			  else{
				console.log("Response is not 200");
				this.isLoading = false;
			  }
			  this.isLoading = false;
			},
			(error) => {
			  console.error('Error during API call:', error);
			  this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
			  this.isLoading = false;
			  this.nopdf=true
			}
		  );



	  }

	// ngOnInit() {
	// 	this.isLoading = true;
	// 	if (
	// 		this.fileUrl.split('.').pop() === 'html' ||
	// 		this.fileUrl.split('.').pop() === 'odt'
	// 	) {
	// 		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
	// 			this.fileUrl
	// 		);
	// 	} else {
	// 		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
	// 			`https://docs.google.com/viewer?url=${this.fileUrl}&embedded=true`
	// 		);
	// 	}

	// 	setTimeout(() => (this.isLoading = false), 2500);
	// }

	// checkIFrame(iframe: any) {
	// 	if (iframe) {
	// 		iframe.onload = () => {
	// 			if (this.checkIFrameSubscription) {
	// 				this.checkIFrameSubscription.unsubscribe();
	// 			}
	// 		};
	// 	}
	// }
	// reloadIFrame(iframe: any) {
	// 	if (iframe) {
	// 		// iframe.src = this.url;
	// 		if (
	// 			this.fileUrl.split('.').pop() === 'html' ||
	// 			this.fileUrl.split('.').pop() === 'odt'
	// 		) {
	// 			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
	// 				this.fileUrl
	// 			);
	// 		} else {
	// 			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
	// 				`https://docs.google.com/viewer?url=${this.fileUrl}&embedded=true`
	// 			);
	// 		}
	// 	}
	// }

// 	ngAfterViewInit() {
// 		let iframe = this.iframeRef ? this.iframeRef.nativeElement : null;

// 		this.checkIFrame(iframe);

// 		this.checkIFrameSubscription = interval(3000)
// 			.pipe(take(Math.round(20000 / 3000)))

// 			.subscribe(() => {
// 				if (iframe == null) {
// 					iframe = this.iframeRef
// 						? this.iframeRef.nativeElement
// 						: null;

// 					this.checkIFrame(iframe);
// 				}

// 				this.reloadIFrame(iframe);
// 			});
// 	}
// }


}
