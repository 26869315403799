import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-emailprompt-modal',
  standalone: true,
  imports: [ReactiveFormsModule ],
  templateUrl: './emailprompt-modal.component.html',
  styleUrl: './emailprompt-modal.component.scss'
})
export class EmailpromptModalComponent {
	emailForm: FormGroup;
	@Input() msg: string = '';

	constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {
		this.emailForm = this.fb.group({
		  email: ['', [Validators.required, Validators.email]]
		});
	  }

	  submitEmail() {
		if (this.emailForm.valid) {
		  this.activeModal.close(this.emailForm.value.email);
		} else {
		  this.emailForm.controls['email'].markAsTouched();
		}
	  }
}
