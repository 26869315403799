import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileViewService {

  constructor(private http: HttpClient) { }


  convertFiletopdf(fileUrl: any) {
	const requestBody = { file_url: fileUrl };
	return this.http.post(`https://unnanu-wmark-uat.azurewebsites.net/watermark`,requestBody,{ responseType: 'text',observe: 'response' },);
}
}
