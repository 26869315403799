import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { Md5 } from 'ts-md5';
import { EventService } from './event.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocketIOService } from './socket-io.service';
import { routes } from '../../app-routes';
import { ProfileDataService } from './profile-data.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
	private serverUrl = environment.urls.serverUrl_Obj;
	private ssnUrl = environment.urls.ssnUrl_Obj;
	private paymentUrl = environment.urls.paymentUrl_Obj;

	private clientId = '86lqpt4c2zlf57';
	private clientSecret = 'WPL_AP1.4Ypq3NNUcoHaKNO6.naoIAg==';
	private redirectUri = 'https://uat.app.unnanu.com/login';
	private linkedinAuthUrl = 'https://www.linkedin.com/oauth/v2/authorization';
	// private linkedinTokenUrl = 'https://www.linkedin.com/oauth/v2/accessToken';
	private linkedinTokenUrl = 'https://uat-recruit-api-v5.unnanu.com/api/v1/user/checklnkuser';
	private linkedinProfileUrl = 'https://api.linkedin.com/v2/userinfo';
	private linkedinEmailUrl =
		'https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))';

	constructor(
		private http: HttpClient,
		private router: Router,
		private utilityService: UtilityService,
		private cookieService: CookieService,
		private eventService: EventService,
		private modalService: NgbModal,
		private socketIOService: SocketIOService,
		private profileDataService: ProfileDataService,
		private toastr: ToastrService
	) {
		this.generateDeviceId();
	}

	signUp(user: any, signUpMethod?: any) {
		console.log('user: ', user);
		if (signUpMethod === undefined) {
			signUpMethod = 'EMAIL';
		}
		let lastName = user.lastName ? user.lastName : signUpMethod.charAt(0).toUpperCase();
		// phone
		let profileImage = user.profileImg?user.profileImg:null;
		console.log('profileImage: ', profileImage);
		let data: any = {
			DeviceId: this.getDeviceId(),
			Email: user.email,
			FirstName: user.firstName,
			LastName: lastName,
			Password: new Md5().appendStr(user.password).end(),
			Platform: 'WEB',
			SignUpMethod: signUpMethod,
			ReferredBy: user.refId,
			Token: user.token,
			profileImage:profileImage
		};
		console.log('data: ', data);

		return this.http.post(`${this.serverUrl}user/signup`, data).pipe(
			map((response: any) => {
				this.setCookie('userEmail', user.email);
				this.setCookie('user_id', response.Data.UserId);
				this.eventService.broadcast('user-signed-up', {
					response: response,
				});
				console.log('response: ', response);
				return response;
			})
		);
	}

	HashSignin(hcode: any) {
		let data = {
			Email: 'empty',
			Password: 'empty',
			DeviceId: this.getDeviceId(),
			Platform: 'WEB',
			LoginType: 'EMAIL',
			Token: hcode,
		};

		return this.http.post(
			this.serverUrl + `user/hashsignin/${hcode}`,
			data
		);
	}

	signIn(user: any) {
		let data: any = {
			Email: user.email,
			Password: new Md5().appendStr(user.password).end(),
			DeviceId: this.getDeviceId(),
			Platform: 'WEB',
			LoginType: 'EMAIL',
		};

		return this.http.post(`${this.serverUrl}user/signin`, data).pipe(
			map((response: any) => {
				let expires = new Date();
				let y = new Date().getFullYear();
				expires.setFullYear(y + 1);
				this.setCookie('userEmail', user.email);
				return response;
			})
		);
	}

	appleAuth(user: any) {
		let data: any = {
			Email: user.email,
			Password: new Md5().appendStr(user.password).end(),
			DeviceId: this.getDeviceId(),
			Platform: 'WEB',
			LoginType: 'APPLE',
			Token: user.token || '',
		};

		return this.http.post(`${this.serverUrl}user/signin`, data);
	}

	googleAuth(user: any) {
		let data: any = {
			Email: user.email,
			Password: new Md5().appendStr(user.password).end(),
			DeviceId: this.getDeviceId(),
			Platform: 'WEB',
			LoginType: 'GOOGLE',
			Token: user.token || '',
		};

		return this.http.post(`${this.serverUrl}user/signin`, data);
	}

	facebookAuth(user: any) {
		let data: any = {
			Email: user.email,
			Password: new Md5().appendStr(user.password).end(),
			DeviceId: this.getDeviceId(),
			Platform: 'WEB',
			LoginType: 'FACEBOOK',
			Token: user.token || '',
			// Phone:
		};

		return this.http.post(`${this.serverUrl}user/signin`, data);
	}
	 checkfbuser(id:any){
		// let data={
		// 	id:id
		// }
		return this.http.get(`${this.serverUrl}user/checkfbuser?id=${id}`);
	 }
	 checklnuser(id:any){
		// let data={
		// 	id:id
		// }
		return this.http.get(`${this.serverUrl}user/checklnuser?id=${id}`);
	 }
	verifyMobileNumber(number: number) {
		let data: any = {
			MobileNumber: number.toString(),
		};
		return this.http
			.post(`${this.serverUrl}user/mobile/verification/code`, data)
			.pipe(
				map((response: any) => {
					this.eventService.broadcast('mobile-verified', {
						response: response,
					});
					return response;
				})
			);
	}

	verifyCode(code: number) {
		let data: any = {
			Code: code.toString(),
		};
		return this.http
			.post(`${this.serverUrl}user/mobile/verification`, data)
			.pipe(
				map((response: any) => {
					this.eventService.broadcast('code-verified', {
						response: response,
					});
					return response;
				})
			);
	}

	redirectUser(stage: any, force: boolean = false) {
		stage = Number(stage);
		if (this.cookieService.check('token') || force) {
			switch (stage) {
				case 0:
					this.router.navigate(['/mobileVerify']);
					break;
				case 1:
					this.router.navigate(['/addPhoto']);
					break;
				case 2:
					this.router.navigate(['/getStarted']);
					break;
				case 3:
					this.router.navigate(['/ssnVerify']);
					break;
				case 4:
					this.router.navigate(['/careerPreferences']);
					break;
				case 5:
					this.router.navigate(['/dashboard']);
					break;
				default:
					this.router.navigate(['/login']);
					break;
			}
		} else {
			this.router.navigate(['/login']);
		}
	}

	skipState(state: any) {
		let data: any = {
			current_stage: state.toString(),
		};
		return this.http.post(`${this.serverUrl}profile/skip`, data);
	}

	addComponent(obj: any) {
		return this.http.post(`${this.serverUrl}profile/information`, obj).pipe(
			map((response: any) => {
				if (response.Code === 200) {
					this.toastr.success(`Successfully Saved`);
				}
				return response;
			})
		);
	}

	submitURLs(obj: any) {
		let data: any = {
			weblinks: obj.toString(),
		};
		return this.http.post(`${this.serverUrl}profile/submitURLs`, data).pipe(
			map((response: any) => {
				if (response.Code === 200) {
					this.toastr.success(`Successfully Saved`);
				}
				return response;
			})
		);
	}

	verifySSN(obj: any) {
		return this.http.post(`${this.serverUrl}profile/ssn`, obj);
	}

	saveSSNInfo(newSsn: any) {
		let data: any = {
			Ssn: newSsn.Ssn,
			DateofBirth: newSsn.DateofBirth,
			PostalCode: newSsn.PostalCode,
		};
		return this.http.post(`${this.ssnUrl}profile/ssnInfo`, data);
	}

	verifyPayment(card: any) {
		let data: any = {
			card_token: card.token,
			selected_plan: card.plan,
		};
		return this.http.post(
			`${this.paymentUrl}recruit/payment/stripe/save`,
			data
		);
	}

	checkPayment() {
		return this.http.get(`${this.paymentUrl}recruit/payment/checkStatus`);
	}

	checkSubPayment() {
		return this.http.get(
			`${this.paymentUrl}recruit/payment/subscription/checkStatus`
		);
	}

	cancelSubscription() {
		return this.http.post(
			`${this.paymentUrl}recruit/payment/stripe/cancel`,
			{}
		);
	}

	updatingCard(card: any) {
		let data: any = {
			new_card_token: card.token,
		};
		return this.http.post(
			`${this.paymentUrl}recruit/payment/card/update`,
			data
		);
	}

	updatingPlan(plan: any) {
		let data: any = {
			new_plan: plan.card_plan,
		};
		return this.http.post(
			`${this.paymentUrl}recruit/payment/changePlan`,
			data
		);
	}

	dreamJob(obj: any) {
		return this.http.post(`${this.serverUrl}profile/job`, obj);
	}

	profileInfo() {
		return this.http.get(`${this.serverUrl}profile`);
	}

	getHeaderDataCount() {
		return this.http.get(`${this.serverUrl}profile/get/headerData`);
	}

	getResumes() {
		return this.http.get(`${this.serverUrl}profile/get/0/resumeData`);
	}

	updateResume(obj: any) {
		return this.http.get(`${this.serverUrl}profile/get/${obj}/resumeData`);
	}

	profileUpdate(obj: any) {
		return this.http.put(`${this.serverUrl}profile/update`, obj).pipe(
			map((response: any) => {
				if (response.Code === 200) {
					// view number 1 for profile
					this.socketIOService.syncViewStatus({
						viewNumber: 1,
						user_id: Number(this.cookieService.get('user_id')),
					});
				}
				this.toastr.success(`Successfully Saved`);
				if (response.Data.UpdateRecords) {
					this.toastr.success(`Successfully Saved`);
				} else if (response.Data.DeleteIds) {
					this.toastr.success(`Successfully Deleted`);
				}
				return response;
			})
		);
	}

	changeEMail(email: any) {
		let data: any = {
			email: email.NewEmail,
		};
		return this.http.post(`${this.serverUrl}user/change/email`, data);
	}

	changePasswd(passwd: any) {
		let data: any = {
			OldPassword: new Md5().appendStr(passwd.OldPassword).end(),
			NewPassword: new Md5().appendStr(passwd.NewPassword).end(),
		};
		return this.http.post(`${this.serverUrl}user/change/password`, data);
	}

	deleteAccount() {
		return this.http.post(`${this.serverUrl}user/deactivate`, {});
	}

	recoverAcc(email: any) {
		return this.http.post(
			`${this.serverUrl}user/reset/password/${encodeURIComponent(
				email
			)}/`,
			{}
		);
	}

	sendInvites(emails: any) {
		let data: any = {
			Emails: emails,
		};
		return this.http.post(`${this.serverUrl}referral/invite`, data);
	}

	getReferralInfo() {
		return this.http.get(`${this.serverUrl}referral`);
	}

	resetPassword(obj: any) {
		return this.http.post(
			`${this.serverUrl}user/reset/password/verify`,
			obj
		);
	}

	sendFeedback(text: any) {
		let data: any = {
			Feedback: text,
		};
		return this.http.post(`${this.serverUrl}user/feedback`, data);
	}

	uploadAttachment(file: any) {
		let fd = new FormData();
		fd.append('uploaded_file', file);

		if (file.type === 'application/pdf') {
			fd.append('file_type', '1');
		} else if (file.type === 'text/html') {
			fd.append('file_type', '4');
		} else if (
			file.type === 'text/plain' ||
			file.name.substr(file.name.indexOf('.') + 1) === 'rtf'
		) {
			fd.append('file_type', '3');
		} else {
			fd.append('file_type', '2');
		}

		fd.append('co_guid', '');

		return this.http.post(file.uploadUrl, fd);
	}

	getActivityLog() {
		return this.http.get(`${this.serverUrl}user/activitylog`);
	}

	saveJob(jobid: any) {
		return this.http.post(
			`${this.serverUrl}user/vacancy/save?job_id=${jobid}`,
			{}
		);
	}

	logoutall() {
		return this.http.post(`${this.serverUrl}user/signoutall`, {});
	}

	loadTags(query: any) {
		return this.http
			.get(`${this.serverUrl}autocomplete/skills/${encodeURI(query)}`)
			.pipe(
				map((response: any) => {
					let skillObjs: any[] = [];
					response.Data.forEach((data: any) => {
						skillObjs.push(data.Skill);
					});
					return skillObjs;
				})
			);
	}

	signOutFromUnnanu(redirectURL?: string) {
		return this.http.post(`${this.serverUrl}user/signout`, {}).pipe(
			map((response: any) => {
				this.clearStorageData(redirectURL);
			})
		);
	}

	clearStorageData(redirectURL?: string) {
		this.socketIOService.disconnect();
		this.cookieService.delete('_jobboard', '/');
		this.cookieService.deleteAll();
		this.cookieService.deleteAll('/');
		this.profileDataService.clearProfileData();

		if (redirectURL) {
			window.location.href = redirectURL;
		} else {
			this.router.resetConfig(routes);
			this.router.navigateByUrl('/login');
		}
	}

	getSocialData() {
		return this.http.get(`${this.serverUrl}profile/social`);
	}

	saveSocialData(data: any) {
		return this.http.post(`${this.serverUrl}profile/postsocial`, data);
	}

	setCookie(
		name: string,
		data: any,
		expire: boolean = true,
		extraOptions?: any
	): void {
		let options: any = {
			sameSite: 'Lax',
			path: '/',
		};
		if (expire) {
			let today = new Date();
			let tomorrow = new Date();
			tomorrow.setDate(today.getDate() + 1);
			options.expires = tomorrow;
		}
		if (extraOptions) {
			options = { ...options, extraOptions };
		}

		this.cookieService.set(name, data, options);
	}

	private generateDeviceId(): void {
		let guid = this.utilityService.genGuid();
		if (!this.cookieService.check('guid')) {
			this.setCookie('guid', guid, false);
		}
	}

	private getDeviceId(): string {
		if (!this.cookieService.check('guid')) {
			let guid = this.utilityService.genGuid();
			this.setCookie('guid', guid, false);
			return guid;
		}
		return this.cookieService.get('guid');
	}

	processAttachmentUrl(attachURL: string): Observable<string> {
		return this.http
			.post(
				'https://unnanu-wmark-uat.azurewebsites.net/watermark',
				{ file_url: attachURL },
				{ responseType: 'text', observe: 'response' }
			)
			.pipe(
				map((response) => {
					if (response.status === 200) {
						// Modify URL as per your requirements
						console.log('sucesr');
						return attachURL
							.replace('resume', 'watermark')
							.replace(
								/\.docx?|\.txt|\.rtf|\.odt|\.html|\.pdf$/i,
								'.pdf'
							);
					} else {
						console.log('Error');

						return attachURL;
					}
				})
			);
	}
	convertFiletopdf(fileUrl: any) {
		const requestBody = { file_url: fileUrl };
		return this.http.post(
			`https://unnanu-wmark-uat.azurewebsites.net/watermark`,
			requestBody,
			{ responseType: 'text', observe: 'response' }
		);
	}

	getAuthorizationUrl(): string {
		// const scope = 'r_liteprofile r_emailaddress openid';
		const state = this.generateRandomState();
		sessionStorage.setItem('oauthState', state);
		return `${this.linkedinAuthUrl}?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&scope=openid%20profile%20email&state=${state}`;
	}
	// Generate a random state for CSRF protection
	private generateRandomState(): string {
		return (
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15)
		);
	}
	getAccessToken(token: string) {


		return this.http.get(`${this.linkedinTokenUrl}?token=${encodeURIComponent(token)}`);

	  }
	  getLinkedInProfile(accessToken: string) {
		return this.http.get(this.linkedinProfileUrl, {
		  headers: { Authorization: `Bearer ${accessToken}` },
		});
	  }
	  getLinkedInEmail(accessToken: string) {
		return this.http.get(this.linkedinEmailUrl, {
		  headers: { Authorization: `Bearer ${accessToken}` },
		});
	  }

	  linkedinAuth(user: any) {
		console.log(user);

		const data: any = {
		  Email: user.email,
		  Password: new Md5().appendStr(user.password).end(),
		  DeviceId: this.getDeviceId(),
		  Platform: 'WEB',
		  LoginType: 'LINKEDIN',
		  Token: user.token || '',
		};

		return this.http.post(`${this.serverUrl}user/signin`, data);
	  }


}
