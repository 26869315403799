<div class="col-xs-12 enable-wrap">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-title">{{UserName}}'s Resumes:</h3>
  </div>
  <div class="modal-body">
    <p>You can deactivate your current resume and either activate an available one or upload a new one below. We strongly recommend keeping a resume active.</p>
    <div class="uploading-wrapper uploaded" style="height: fit-content;">
      <table class="pretty-table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Last Updated</th>
            <th style="text-align: right;">Action</th>
          </tr>
        </thead>
        <tbody style="width: 192%;">
          @for (resume of ResumeData; track resume) {
            <tr>
              <td [ngClass]="{ 'active-status': resume.status === true }">
                <!-- <a class="icon-download-alt" style="padding: 5px;" target="_self"
                href="getAttachmentViewUrl(resume.attachment_url)"></a> -->
				<a class="icon-download-alt" style="padding: 5px;" target="_self"
				(click)="onDownload($event, resume.attachment_url)"></a>
                <a class="resume-title cursor-pointer" attachmentView
                  [value]="getAttachmentViewUrl(resume.attachment_url)">
                  {{ resume.file_name | ellipsis:55 }}
                </a></td>
                <td [ngClass]="{ 'active-status': resume.status === true }">{{ resume.updated_at | date: 'MM/dd/yyyy' }}</td>
                <td style="text-align: right;" >
                  @if (resume.status === true) {
                    <button class="delete-btn" (click)="updateResume(resume.resume_id, true,resume.file_name)">
                      Deactivate
                    </button>
                  }
                  @if (resume.status !== true) {
                    <button class="activate-btn" (click)="updateResume(resume.resume_id, false,resume.file_name)">
                      Activate
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <p class="size">File size: 2MB or less | File types: '.doc', '.docx', '.txt', '.rtf', '.odt', '.html' and '.pdf'.</p>
    </div>
    <div class="modal-footer">
        <div class="upload-new-file-button d-flex align-items-center float-start" [hidden]="enableDelete">
			<button class="btn  me-2" [disabled]="uploading" (click)="fileInput.click()">+ Resume</button>
			<input #fileInput [disabled]="uploading" class="form-control" type="file"
			  accept=".pdf,.txt,.doc,.docx,.html,.rtf,.odt" (change)="onFileChange($event)" style="display: none;">
		  </div>
		  <button class="cancel-changes-button large ms-auto" (click)="closeThisDialog()">Cancel</button>
      <button class="save-changes-button large ms-auto" [hidden]="enableDelete" [disabled]="!enableSave"
      (click)="update()">Save</button>
      <button class="save-changes-button large ms-auto" [hidden]="!enableDelete" (click)="delete()">Save</button>

    </div>
  </div>
