<!-- <div class="modal-header">
<h4 class="modal-title" id="modal-title"></h4>
</div>
<div class="modal-body">
  <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="height: 575px;">

    <div class="spinner-grow" role="status">
      <span class="visually-hidden align-item-center"></span>
    </div>

  </div>
  <div *ngIf="!isLoading">
    <iframe [src]="url"  style="width:100%; height:575px;" frameborder="0"></iframe>
  </div>
</div>
<div class="modal-footer text-right">
</div> -->
<div class="modal-header">
  <h4 class="modal-title" id="modal-title"></h4>
</div>
<div class="modal-body">
  @if (isLoading) {
    <div class="d-flex justify-content-center align-items-center" style="height: 575px;">
      <!-- <p>Loading...</p> -->
      <div class="spinner-grow" role="status">
        <span class="visually-hidden align-item-center"></span>
      </div>
    </div>
  }
  @if (!isLoading) {
	@if(!nopdf){
		<div>
			<!-- <iframe [src]="url "  style="width:100%; height:575px;" frameborder="0"></iframe> -->
			<pdf-viewer [src]="url"
			[render-text]="true"
			[original-size]="false"
			style="width: 470px; height: 500px"
	  ></pdf-viewer>
		  </div>
	}
	@else {
			<iframe [src]="url "  style="width:100%; height:575px;" frameborder="0"></iframe>
	}

  }
</div>
<div class="modal-footer text-right">
</div>

