
import {  Routes } from '@angular/router';
import { LinkedinCallbackComponent } from './shared/components/linkedin-callback/linkedin-callback.component';

export const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: 'redirect', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
	{ path: 'activate', loadChildren: () => import('./activate/activate.module').then(m => m.ActivateModule) },
	{ path: 'forgotPassword', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
	{ path: 'reset', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
	{ path: 'jobboard', loadChildren: () => import('./jobboard-api-recruit/jobboard-api-recruit.module').then(m => m.JobboardApiRecruitModule) },
	{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
	{ path: 'message', loadChildren: () => import('./message/message.module').then(m => m.MessageModule) },
	{ path: 'offers', loadChildren: () => import('./offers/offers.module').then(m => m.OffersModule) },
	{ path: 'interviews', loadChildren: () => import('./interviews/interviews.module').then(m => m.InterviewsModule) },
	{ path: 'more', loadChildren: () => import('./more/more.module').then(m => m.MoreModule) },
	{ path: 'careerPreferences', loadChildren: () => import('./career-preferences/career-preferences.module').then(m => m.CareerPreferencesModule) },
	{ path: 'ssnVerify', loadChildren: () => import('./ssn/ssn.module').then(m => m.SsnModule) },
	{ path: 'mobileVerify', loadChildren: () => import('./mobile-verify/mobile-verify.module').then(m => m.MobileVerifyModule) },
	{ path: 'addPhoto', loadChildren: () => import('./add-photo/add-photo.module').then(m => m.AddPhotoModule) },
	{ path: 'getStarted', loadChildren: () => import('./get-started/get-started.module').then(m => m.GetStartedModule) },
	{ path: 'auth/linkedin/callback', component: LinkedinCallbackComponent },
];



