import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { FooterModule } from './app/footer/footer.module';
import { HeaderModule } from './app/header/header.module';
import { CoreModule } from './app/core/core.module';

import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { GoogleAnalyticsService } from './app/core/services/google-analytics.service';
import { Title, BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { routes } from './app/app-routes';
import { provideRouter } from '@angular/router';

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, CoreModule, HeaderModule, FooterModule),
        Title,
        GoogleAnalyticsService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
		provideRouter(routes)
    ]
})
	.catch(err => console.error(err));
