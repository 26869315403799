import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JobBoardRecruitService {
	private serverUrl = environment.urls.serverUrl_Obj;
	private signupUrl = environment.urls.SIGNUP_APP;


	constructor(private http: HttpClient,
		private cookieService: CookieService) { }

	// get selected job data
	getSelectedJobData(jobId: any) {
		return this.http.get(`${this.serverUrl}user/vacancy/getVacancyDetails/${jobId}`);
	}

	// apply job
	applyForJob(vacancyId: any, source: any) {
		if (!source) {
			source = 'UWJB';
		}

		let data: any = {
			vacancy_id: vacancyId
		}

		return this.http.post(`${this.serverUrl}user/vacancy/apply/${source}/web`, data);
	}

	// apply job - submit questions
	applyForJob_SubmitQuestions(application_answers: any, vacancyId: any, source: any) {
		if (!source) {
			source = 'UWJB';
		}

		return this.http.post(`${this.serverUrl}user/vacancy/questionaire/${vacancyId}/submit/${source}/web`, application_answers);
	}

	//load application questions
	getApplicationQuestions(vacancyId: any) {
		return this.http.get(`${this.serverUrl}user/vacancy/questionaire?vacancyId=${vacancyId}`);
	}
	checkemail(email:any){
		// https://uat-hire-oth-v5.unnanu.com/api/v1/account/recruit/CheckEmail?doc=262025@unnanu.com
		return this.http.get(`${this.signupUrl}/api/v1/account/recruit/CheckEmail?doc=${email}`);
	}
	quicksignup(jobid: any,resumedata:any) {
		console.log('resumedata: ', resumedata);
		return this.http.post(`${this.signupUrl}/api/v1/account/recruit/SendEmailRecruit?jid=${jobid}`,resumedata);
		// return this.http.post(`${this.signupUrl}/api/v1/account/recruit/${jobid}/SendEmail`,resumedata);
	}

}
