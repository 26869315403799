<div class="modal-v2">
	<div class="modal-header">
		<h1 class="modal-title" id="modal-title">Take video</h1>
		<button type="button" class="close" aria-label="Close" (click)="close()">
		</button>
	</div>
	<div class="modal-body">
		<camera></camera>
	</div>
	<div class="modal-footer text-right">
		<button [hidden]="!showRecordButton" type="button" class="btn record-video-button"
			[ngClass]="{'recording': isRecording}" (click)="changeRecorderClass()">
			<svg class="start-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13">
				<path fill="#FFF" fill-rule="evenodd"
					d="M17.595.378a.693.693 0 0 0-.25-.05.594.594 0 0 0-.452.192l-4.045 4.077V2.924c0-.805-.283-1.493-.849-2.064A2.77 2.77 0 0 0 9.957.003H2.89A2.77 2.77 0 0 0 .848.86 2.828 2.828 0 0 0 0 2.924v7.139c0 .805.283 1.492.848 2.064a2.77 2.77 0 0 0 2.043.857h7.066A2.77 2.77 0 0 0 12 12.127a2.827 2.827 0 0 0 .848-2.064V8.38l4.045 4.086c.12.129.27.193.451.193.08 0 .164-.017.251-.05a.606.606 0 0 0 .392-.599V.977a.607.607 0 0 0-.392-.599z" />
			</svg>
			<svg class="stop-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
				<rect width="16" height="16" y="2" fill="#FFF" fill-rule="nonzero" opacity=".99" rx="2"
					transform="translate(0 -2)" />
			</svg>
			<span class="start-text">Start Recording</span>
			<span class="stop-text">Stop  </span>
			<span class="record-time">  00:{{timerText}}</span>
		</button>
		<div [hidden]="showRecordButton">
			<div class="retake-buttons d-flex justify-content-between">
				<button (click)="retakeVideo()" type="button" class="btn retake-video-button">Retake</button>
				<button (click)="selectVideo()" type="button" class="btn save-video-button">Save</button>
			</div>
		</div>
	</div>
</div>
