<div class="modal-header">
  <h4 class="modal-title">Refer Gmail contacts</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group col-xs-12">
      <input [(ngModel)]="searchText" type="text" name="" class="form-control live-search-gmail"
        placeholder="Search for email addresses">
    </div>
    <div class="form-group col-xs-12">
      <div class="contact-list">
        <div class="contact-list-all">
          @for (contact of this.contactList | filter:'email':searchText; track contact) {
            <div class="contact-row">
              <label for="contact_row_1">
                <span class="contact-email">{{contact.email}}</span>
                <span class="contact-check">
                  <input [(ngModel)]="contact.checked" type="checkbox" class="email"
                    value="{{contact.email}}" id="contact_row_1">
                </span>
              </label>
            </div>
          }
        </div>
      </div>
      <!-- 1 person was successfully invited from google contacts! -->
      <div class="contact-select-all text-right">
        <span (click)="selecteAll()"><a href="">Select All</a> - - - </span>
        <span (click)="DeselecteAll()"><a href="">Unselect All</a> </span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-right">
  <button type="button" class="btn referCandidatesButton" (click)="referCandidates()">Refer candidates</button>
</div>
