import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { DefaultHeaderComponent } from './default-header/default-header.component';
import { HeaderComponent } from './header.component';

@NgModule({
    imports: [
        RouterModule,
        SharedModule,
        HeaderComponent,
        DefaultHeaderComponent,
        DashboardHeaderComponent
    ],
    exports: [
        HeaderComponent
    ],
    providers: []
})
export class HeaderModule { }
