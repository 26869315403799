import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'confirm-alert',
    templateUrl: './confirm-alert.component.html',
    styleUrls: ['./confirm-alert.component.scss'],
    standalone: true
})
export class ConfirmAlertComponent {

	@Input() header: string = 'Information';
	@Input() msg: string = '';

	constructor(public modal: NgbActiveModal) { }

	yes() {
		this.modal.close();
	}

	no() {
		this.modal.dismiss();
	}

}
