<div class="profile-content-card-item">
  @if (data.VideoUrl) {
    <div class="card-video-panel">
      <div class="card-video-preview">
        <div class="card-video-preview-image"
        [ngStyle]="{'background-image':'url('+getImageSrc(data.ThumbnailUrl)+')'}"></div>
        <i class="loading preloader-icon-v1"></i>
      </div>
      <div class="card-preview-button-wrapper">
        <svg width="124px" height="80px" viewBox="0 0 124 80" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="svg" fill="#266ADC">
              <polygon id="Path" points="37.7537281 0 -1.7178052e-15 80 123.78091 80 123.78091 0"></polygon>
            </g>
          </g>
        </svg>
        <button (click)="edit()" class="btn play-card-video">
          <img src="../../../../assets/images/play-video-button.png"
            srcset="../../../../assets/images/play-video-button@2x.png 2x, ../../../../assets/images/play-video-button@3x.png 3x"
            class="play-video-icon">
        </button>
      </div>
    </div>
  }
  <div class="card-item-body">
    <!-- <div class="resumeEntryWrapper"> -->
    <div class="media d-flex align-items-start">
      <div class="media-thumbnail" style="background-image:url('{{data.Authority.LogoUrl}}');"></div>
      <div class="media-body flex-grow-1">
        <div class="card-item-duration">{{data.StartDate}} - {{(data.EndDate === 0) ? 'Present' : data.EndDate}}
        </div>
        <div class="card-item-location">{{data.Title}}</div>
        <div class="card-item-title" style="font-size: 12px;">{{data.Authority.Name}} - {{data.LicenceNumber}}
        </div>
      </div>
    </div>

    <button (click)="edit()" class="btn edit-entry-button btn-sm" type="button"
      [hidden]="loadingButton || !isSaved">
      <i class="img img_profileEditIconSmall_1" alt=""></i>Edit
    </button>
    <button class="btn updating-entry-button btn-sm" type="button" [hidden]="!loadingButton || isSaved">
      <i class="signinsignupPreloader" alt=""></i>Updating
    </button>
  <!-- </div> -->
</div>
<div class="card-item-footer">
  <hr>
    <div class="card-description">{{data.Notes | ellipsis:numLimit}}@if (numLimit === 285 && data.Notes.length > 285) {
      <span
      >&hellip;</span>
    }</div>
    <div class="see-more">
      @if (data.Notes.length > 285) {
        <button class="btn view-more-button" [hidden]="showReadLess"
        (click)="readMore()">View More</button>
      }
      @if (showReadLess) {
        <button class="btn view-less-button" (click)="readLess()">Collapse</button>
      }
    </div>
  </div>
</div>
