import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'filter',
    pure: false,
    standalone: true
})

export class FilterPipe implements PipeTransform {

	transform(array: any[], prop: string, term: string | boolean): any[] {
		//check if search term is undefined
		if (term === undefined || prop === undefined) return array;
		//return updates array
		return array.filter((item) => {
			if (typeof term === 'string') {
				return item[prop].toLowerCase().includes(term.toLowerCase());
			}
			if (typeof term === 'boolean') {
				return item[prop] === term;
			}
		});
	}
}
