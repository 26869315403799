import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgClass } from '@angular/common';
import { InputRestrictionDirective } from '../../directives/input-restriction.directive';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'city-suggestion',
    templateUrl: './city-suggestion.component.html',
    styleUrls: ['./city-suggestion.component.scss'],
    standalone: true,
    imports: [FormsModule, NgbTypeahead, InputRestrictionDirective, NgClass]
})
export class CitySuggestionComponent implements OnInit {

	@Input() basicInfo: any;
	@Input() data: any;
	@Input() parentobj: any;
	@Input() showCityError: boolean = false;

	@Output() cityUpdated: EventEmitter<any> = new EventEmitter<any>();

	public city: string = '';
	public predictions: any[] = [];
	public citySelected: boolean = false;
	public cityErrorMessage: string = '';
	public hasToShowCitySuggestions: boolean = false;
	public oldData: any;

	constructor() { }

	ngOnInit() {
		if (this.basicInfo) {
			let obj = this.basicInfo;
			this.oldData = this.basicInfo;
			this.city = obj.Location;
		} else if (this.data !== undefined) {
			this.oldData = this.data;
			if (
				this.data.Location !== undefined &&
				this.data.Location.Locality !== undefined
			) {
				this.city = this.data.Location.Locality;
			} else if (
				this.data.city !== undefined &&
				this.data.Location === undefined
			) {
				this.city = this.data.city;
			} else {
				this.city = this.data.Location;
			}
		} else {
			this.city = '';
		}

		this.parentobj.city = this.city;
		this.parentobj.showCityError = false;
	}

	// suggest cities
	suggestCities() {
		this.validateCity();
		this.citySelected = false;
		if (this.city.length >= 1) {
			let service = new google.maps.places.AutocompleteService();
			let result = new Observable<string[]>(observer => {
				service.getPlacePredictions({ input: this.city, types: ['(cities)'] },
					(predictions, status) => {
						if (status == google.maps.places.PlacesServiceStatus.OK) {
							let response = {
								status: 200,
								data: {
									predictions: predictions,
								},
							};
							this.predictions = response.data.predictions;
							observer.next(this.predictions);
							observer.complete();
						} else {
							this.hasToShowCitySuggestions = true;
							observer.next([]);
							observer.complete();
						}
					});
			});
			return result;
		} else {
			this.hasToShowCitySuggestions = false;
			return of([]);
		}
	}

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.suggestCities())
		)

	formatter = (result: any) => result.description;

	citySelectedFn(event: any) {
		event.preventDefault();
		let cityName = event.item.description;
		if (cityName !== '') {
			this.city = cityName;
			this.parentobj.city = cityName;
			this.hasToShowCitySuggestions = false;
			this.citySelected = true;
			this.cityUpdated.emit(true);
		} else {
			this.city = '';
			this.parentobj.city = '';
		}
	}

	// city field validation
	private validateCity() {
		if (!this.city || this.city === '') {
			this.cityErrorMessage = 'Please select a City';
			this.showCityError = true;
			this.parentobj.showCityError = true;
		} else {
			this.showCityError = false;
			this.parentobj.showCityError = false;
		}
	}

	//  hide city suggestions
	hideCitySuggestions() {
		this.hasToShowCitySuggestions = false;
		if (!this.citySelected) {
			if (this.oldData) {
				if (
					this.oldData.Location !== undefined &&
					this.oldData.Location.Locality !== undefined
				) {
					if (this.oldData.Location.Locality !== this.city) {
						this.city = '';
					} else if (this.oldData.Location !== this.city) {
						this.city = '';
					}
				}
			} else {
				this.city = '';
			}

			this.validateCity();
		}
	}

}
