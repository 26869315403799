<div class="edit-profile-dialog-content no-close-btn-header d-flex flex-row"
  [ngClass]="{'edit-resume-popup': ctrl === 'PROFILE_ADD_CER'}">
  <div class="popup-column-group profile-photo-video-column">
    <div class="column-title">Unique Talents</div>
    <div class="photo-video-ui">
      <div class="photo-video-container">
        <div class="profile-video-ui-wrapper">
          <video (onloadeddata)="positionVideo()" id="vidPreview" [hidden]="!videoSelected"
          [src]="selectedVideoUrl" autoplay loop></video>
        </div>
        <img id="imgPreview" [hidden]="videoSelected" class="imgPreview" height="100%" src="{{selectedPhoto}}">
        <img [hidden]="photoSelected || videoSelected" class="noProfilePhoto_img" width="100%" height="100%"
          src="../../../../assets/images/default-profile-picture-new.svg">
          <div class="ui-popover openToggler">
            <button id="muteBtn" [hidden]="!videoSelected" (click)="muted(!muteClassAdd)"
              class="item-video-sound-control" [ngClass]="{'mute' : muteClassAdd}">
            <img class="audio-control-icon-img mute-icon"
              src="../../../../assets/images/sound-muted-icon.png"
              srcset="../../../../assets/images/sound-muted-icon@2x.png 2x, ../../../../assets/images/sound-muted-icon@3x.png 3x">
              <img class="audio-control-icon-img sound-icon"
                src="../../../../assets/images/sound-unmuted-icon.png"
                srcset="../../../../assets/images/sound-unmuted-icon@2x.png 2x, ../../../../assets/images/sound-ummuted-icon@3x.png 3x">
              </button>
            </div>
          </div>
          <div ngbDropdown class="camera-menu dropdown">
            <button ngbDropdownToggle class="btn dropdown-toggle" type="button" id="cameraMenuDropdown">
              <img src="../../../../assets/images/profile-camera-icon.png"
                srcset="../../../../assets/images/profile-camera-icon@2x.png 2x, ../../../../assets/images/profile-camera-icon@3x.png 3x"
                class="profile-camera-icon">
            </button>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="cameraMenuDropdown">
              <a ngbDropdownItem (click)="recordVideo()" class="dropdown-item">Take a Video</a>
            </div>
          </div>
        </div>
        <div class="photo-video-hint">
          Add a video talking about your talents, such as unique strengths, awards, certifications, recognitions,
          volunteering, social causes and any related information.
        </div>
      </div>
      <div class="popup-column-group form-container-column edit-profile-content">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">Talent<span class="requiredAsterix">*</span></label>
            <input inputRestriction="allChars" [(ngModel)]="certificationName"
              [ngClass]="{'error': showCertificationNameError}" maxlength="100"
			  (ngModelChange)="validateCertificationName()" (blur)="validateCertificationName()" type="" name=""
              class="form-control unnanu-text-input-style-1" id="" value="" placeholder="Enter talent" required
              (ngModelChange)="onChange($event)"/>
            <label class="error" [hidden]="!showCertificationNameError">{{ certificationNameErrorMessage
            }}</label>
          </div>
          <div class="unnanu-form-group-style-1 has-sussgestions-dropdown">
            <label class="unnanu-input-label-style-1">Reference<span class="requiredAsterix">*</span></label>
            <input inputRestriction="noSpecialChars" [ngClass]="{'error': showCompanyError}" (blur)="hideCompanySuggestions()"
              maxlength="100" [(ngModel)]="company" type="" name="company"
              class="form-control unnanu-text-input-style-1" id="" value=""
              placeholder="Enter reference from list or add" [ngbTypeahead]="search"
              (selectItem)="companySelectedFn($event)" [resultTemplate]="rt" required (ngModelChange)="onChange($event)"/>
            <ng-template #rt let-r="result" let-t="term">
              <img src="{{r.LogoUrl || '../../../../../../assets/images/education-default.jpg'}}" class="mr-1"
                style="width: 16px">
              <ngb-highlight [result]="r.Name" [term]="t"></ngb-highlight>
            </ng-template>
            <label class="error" [hidden]="!showCompanyError">Enter
            Authority</label>
          </div>
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">Talent Type<span class="requiredAsterix">*</span></label>
            <input inputRestriction="allChars" [(ngModel)]="licenceNumber" [ngClass]="{'error': showLicenceNumberError}"
              maxlength="80" (change)="validateLicenceNumber()" (blur)="validateLicenceNumber()" type="" name=""
              class="form-control unnanu-text-input-style-1" id="" value="" placeholder="Enter talent type"
              required (ngModelChange)="onChange($event)"/>
            <label class="error" [hidden]="!showLicenceNumberError">{{ LicenceNumberErrorMessage }}</label>
          </div>
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">Description</label>
            <textarea [(ngModel)]="notes" rows="1" class="form-control unnanu-text-input-style-1"
            [ngClass]="{error: remaining() < 0}" maxlength="{{ maxLength }}" (ngModelChange)="onChange($event)"></textarea>
            @if (remaining() < 0) {
              <span>{{ remaining() }}</span>
            }
          </div>
          <div class="unnanu-form-group-style-1">
            <label class="unnanu-input-label-style-1">Dates<span class="requiredAsterix">*</span></label>
            <div class="has-no-end-time">
              <span class="unnanu-check-box-style-1">
                <input id="ItemDoesNotExpire" [(ngModel)]="isCurrentlyWorking" type="checkbox" name=""
                  class="form-check" (click)="currentlyWorking()" (ngModelChange)="onChange($event)" />
                <label for="ItemDoesNotExpire"></label>
              </span>
              <label class="has-no-end-time-label">Does Not Expire</label>
            </div>
            <div class="time-duration-wrapper d-flex">
              <div class="duration-time">
                <div class="month-year-group">
                  <div class="year">
                    <select [(ngModel)]="startYear" class="form-control periodMenu yearMenu"
                      name="date_start[year]" required (ngModelChange)="onChange($event)">
                      <option value="Year:">Year</option>
                      @for (i of getArray(64); track i; ) {
                        <option
                          value="{{ currentYear - $index }}">
                        {{ currentYear - $index }}</option>
                      }
                    </select>
                  </div>
                </div>
                <label class="error" [hidden]="!startDateError">{{startDateErrorMessage}}</label>
                <label class="error" [hidden]="!timePeriodError">{{timePeriodErrorMessage}}</label>
              </div>
              <div class="duration-to-label">-</div>
              <!-- Begin of the to time select dropdown -->
              <!-- This must hide when clicked on the checkbox -->
              <div class="duration-time duration-end-time" [hidden]="isCurrentlyWorking">
                <div class="month-year-group">
                  <div class="year">
                    <select [(ngModel)]="endYear" class="form-control periodMenu yearMenu"
                      name="date_start[year]" (ngModelChange)="onChange($event)">
                      <option value="Year:">Year</option>
                      @for (i of getArray(50); track i; ) {
                        <option
                          value="{{ maximumYear - $index }}">
                        {{ maximumYear - $index }}</option>
                      }
                    </select>
                  </div>
                </div>
                <label class="error" [hidden]="!endDateError">{{endDateErrorMessage}}</label>
              </div>
              <!-- End of the to time select dropdown -->
              <div [hidden]="!isCurrentlyWorking" class="duration-time duration-end-time">
                <div class="no-end-date-label">Present</div>
              </div>
            </div>
            <br>
              <span class="requiredAsterix">*</span>
              <label class="unnanu-input-label-style-1">Required Information </label>
            </div>
          </div>
          <div class="modal-footer text-end">
            <div class="footer-buttons-row d-flex justify-content-between align-items-center">
              @if (data.COGUID) {
                <button type="button" class="delete-card-button large float-left" (click)="deleteData()"
                  >
                  Delete
                </button>
              }
			  <div>
              <button type="button" class="cancel-changes-button large " (click)="close()">
                Cancel
              </button>
              <button type="button" class="save-changes-button large ms-2"
                [disabled]="!valueUpdated || !certificationName || !company || !licenceNumber || !startYear || startYear === 'Year:' || hasToShowCompanySuggestions"
                (click)="saveData()">
                Save Changes
              </button>
			</div>
            </div>
          </div>
        </div>
      </div>
