import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventService } from '../../../core/services/event.service';
import * as RecordRTC from 'recordrtc';

@Component({
    selector: 'camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
    standalone: true
})
export class CameraComponent implements OnInit, OnDestroy, AfterViewInit {

	private localStream: any;
	private recorder: any;

	@ViewChild('video') videoElem: any;

	constructor(private eventService: EventService) {

	}

	ngOnInit() {
		this.eventService.subscribe('dismiss-camera', (value) => {
			this.localStream.getTracks().forEach((track: any) => {
				track.stop();
			});
		});

		this.eventService.subscribe('start-recording-camera', (value) => {
			console.log('--------- start-recording-camera');
			if (this.localStream.active) {
				this.recorder = new RecordRTC(this.localStream, {
					type: 'video',
				});
				this.videoElem.nativeElement.srcObject = this.localStream;
				this.videoElem.nativeElement.play();
				this.recorder.startRecording();

			} else {
				console.log('no active local stream');
			}
		});

		this.eventService.subscribe('stop-recording-camera', (value) => {
			console.log('--------- stop-recording-camera');
			if (this.localStream.active) {
				this.videoElem.nativeElement.muted = true;
				this.videoElem.nativeElement.volume = 0;
				this.recorder.stopRecording(this.stopRecordingCallback.bind(this));
			} else {
				console.log('no active local stream');
			}
		});

		// retake video
		this.eventService.subscribe('camera-on', (value) => {
			this.recorder = new RecordRTC(this.localStream, {
				type: 'video',
			});
			this.videoElem.nativeElement.muted = true;
			this.videoElem.nativeElement.volume = 0;
			this.videoElem.nativeElement.srcObject = this.localStream;

			this.videoElem.nativeElement.play();
		});
	}

	ngOnDestroy() {
		this.localStream.getTracks().forEach((track: any) => {
			track.stop();
		});
	}

	ngAfterViewInit() {
		// set the initial state of the video
		let video: HTMLVideoElement = this.videoElem.nativeElement;
		video.autoplay = false;
		video.muted = true;
		video.volume = 0;
		this.captureCamera();
	}

	captureCamera() {
		let constraints = {
			audio: true,
			video: true,
		};
		navigator.mediaDevices
			.getUserMedia(constraints)
			.then(this.successCallback.bind(this), this.errorCallback.bind(this));
	}

	successCallback(camera: any) {
		this.localStream = camera;
		this.eventService.broadcast('camera-started', {
			localStream: this.localStream,
		});

		this.recorder = new RecordRTC(camera, {
			type: 'video',
		});

		this.videoElem.nativeElement.srcObject = camera;
		this.videoElem.nativeElement.play();
	}

	errorCallback(error: any) {
		console.log(error);
	}

	stopRecordingCallback(videoURL: any) {
		this.videoElem.nativeElement.srcObject = null;
		let recordedBlob = this.recorder.getBlob();
		this.videoElem.nativeElement.src = URL.createObjectURL(recordedBlob);

		this.videoElem.nativeElement.muted = false;
		this.videoElem.nativeElement.volume = 1;

		this.videoElem.nativeElement.play();

		if (recordedBlob !== undefined) {
			this.eventService.broadcast('camera-recording-complete', {
				videoData: recordedBlob,
				url: videoURL,
				localStream: this.localStream,
			});
		}
	}

}
