@if (!resumeName || enableUploadInEdit) {
  <div class="enable-edit">
    <div class="modal-header">
      @if (!editInProgress) {
        <h3 class="modal-title" id="modal-title">Add Resume</h3>
      }
      @if (editInProgress) {
        <h3 class="modal-title" id="modal-title">Edit Resume</h3>
      }
    </div>
    <div class="modal-body">
      @if (!editInProgress) {
        <p>Upload your current resume using the option below.</p>
      }
      @if (editInProgress) {
        <p>You can deactivate your current resume and upload a new one below.</p>
      }
      @if (uploadAttachment) {
        <div class="uploading-wrapper">
          <label class="custom-file">
            <input id="file" class="custom-file-input" type="file" accept=".pdf,.txt,.doc,.docx,.html,.rtf,.odt"
              (change)="onFileChange($event)">
          </label>
          <div class="upload-button text-center" style="padding-top: 7px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="20" viewBox="0 0 29 20">
              <path fill="#000" fill-rule="nonzero"
                d="M23.442 7.5C22.596 3.25 18.97 0 14.5 0a8.81 8.81 0 0 0-7.975 5C2.779 5.5 0 8.625 0 12.5 0 16.625 3.263 20 7.25 20h15.708C26.342 20 29 17.25 29 13.75c0-3.25-2.538-6-5.558-6.25zm-6.525 3.75v5h-4.834v-5H8.458L14.5 5l6.042 6.25h-3.625z"
                opacity=".6" />
            </svg>Upload Resume
          </div>
        </div>
      }
      @if (uploading) {
        <div class="uploading-wrapper uploading">
          <p class="text-center"> Uploading...</p>
        </div>
      }
      @if (showUploaded && !uploading) {
        <div class="uploading-wrapper uploaded">
          <p class="">
            {{fileName | ellipsis:30}}'s Current Resume
          </p>
          <span class="dlt" [hidden]="resumeName" (click)="clearAttachment()"></span>
        </div>
      }
      <p class="size">File size: 2MB or less | File types: '.doc', '.docx', '.txt', '.rtf', '.odt', '.html' and '.pdf'.</p>
    </div>
    @if (!resumeName || (enableUploadInEdit && !hideSaveButton)) {
      <div class="modal-footer text-right">
        <button class="cancel-changes-button large" (click)="closeThisDialog()">Cancel</button>
        <button class="save-changes-button large" [disabled]="disableSave" (click)="save()">Save</button>
      </div>
    }
  </div>
}

@if (resumeName) {
  <div class="col-xs-12 enable-wrap">
    @if (!enableUploadInEdit) {
      <div class="modal-header">
        <h3 class="modal-title" id="modal-title">Edit Resume</h3>
      </div>
    }
    @if (!enableUploadInEdit) {
      <div class="modal-body">
        <p>You can deactivate your current resume and upload a new one below.</p>
        <div class="uploading-wrapper uploaded">
          <div class="">
            {{fileName | ellipsis:30}}'s Current Resume
          </div>
          <span class="dlt"
          (click)="enableDelete = true;enableUploadInEdit = true;hideSaveButton = true;editInProgress=true"></span>
        </div>
        <p class="size">File size: 2MB or less | File types: '.doc', '.docx', '.txt', '.rtf', '.odt', '.html' and '.pdf'.</p>
      </div>
    }
    <div class="modal-footer">
		@if(!hideAddResume){
			<div class="upload-new-file-button d-flex align-items-center float-start" [hidden]="enableDelete"
			(click)="enableUploadInEdit = true;hideSaveButton = true;uploadAttachment = false;showUploaded = true;editInProgress= true">
			<button class="btn  me-2" [disabled]="uploading" (click)="fileInput.click()">+ Resume</button>

			<input #fileInput [disabled]="uploading" id="file" class="form-control" type="file"
			  accept=".pdf,.txt,.doc,.docx,.html,.rtf,.odt" style="display: none;" (change)="onFileChange($event)">
		  </div>
		}

	  <button class="cancel-changes-button large ms-auto" (click)="closeThisDialog()">Cancel</button>
      <button class="save-changes-button large ms-auto" [hidden]="enableDelete" [disabled]="!enableSave"
      (click)="update()">Save</button>
      <button class="save-changes-button large ms-auto" [hidden]="!enableDelete" (click)="delete()">Save</button>

    </div>
  </div>
}
